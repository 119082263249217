import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Button, Table } from "react-bootstrap"
import { HiOutlineDocumentAdd } from "react-icons/hi"
import { navigate } from "gatsby"
import moment from "moment"
import {
  getCreditNotes,
  clearNewCreditNote,
  cancelCreditNote,
  deleteCreditNote,
  saveCreditNote,
} from "../../state/actions/creditNote"
import { setUsers } from "../../state/actions/user"
import {
  FaFileInvoiceDollar,
  FaArrowLeft,
  FaArrowRight,
  FaSearch,
} from "react-icons/fa"
import Loading from "../loading"

import styles from "../../styles/components/quotations/quotations.module.scss"

const CreditNotes = ({
  creditNotes,
  totalCreditNotes,
  totalPages,
  customers,
  users,
  getCreditNotes,
  setUsers,
  clearNewCreditNote,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")
  const [value, setValue] = useState("")

  useEffect(() => {
    setUsers()
    getCreditNotes({
      skip: currentPage,
      limit: itemsPerPage,
      search: searchTerm,
    })
  }, [currentPage, itemsPerPage, searchTerm, creditNotes])

  if (!creditNotes && !searchTerm)
    return <Loading text="Loading Credit Notes..." />

  const onSearch = searchName => {
    setSearchTerm(searchName)
    setValue(searchName)
    setCurrentPage(1)
  }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">
          <FaFileInvoiceDollar />
          Credit Notes
        </h1>
        <div className="action-buttons">
          <Button
            size="lg"
            variant="success"
            onClick={() => {
              clearNewCreditNote()
              navigate("/app/credit-notes/new")
            }}
          >
            <HiOutlineDocumentAdd
              className="mb-1 mr-2"
              style={{ fontSize: "16px" }}
            />
            New Credit Note
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {/* Search */}
        {/* <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />
          <FaSearch className={styles.searchIcon} />
        </div> */}
        {/* <ul className={styles.dropdown}>
          {customers
            .filter(customer => {
              const searchName = value.toLowerCase()
              const customerName = customer.name.toLowerCase()
              return (
                searchName &&
                customerName.includes(searchName) &&
                customerName !== searchName
              )
            })
            .map(customer => (
              <div
                className={styles.dropdownRow}
                onClick={() => onSearch(customer?.name)}
              >
                {customer?.name}
              </div>
            ))}
        </ul> */}
        {creditNotes && creditNotes.length > 0 ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Credit Note Number</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {creditNotes
                  ?.sort(
                    (a, b) =>
                      Number(b.creditNoteNumber?.split("-")[1]) -
                      Number(a.creditNoteNumber?.split("-")[1])
                  )
                  ?.map(creditNote => (
                    <tr
                      key={creditNote._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/app/credit-notes/${creditNote._id}`)
                      }}
                    >
                      <td>{creditNote.creditNoteNumber}</td>
                      <td>
                        {creditNote.customerCode} -{" "}
                        {
                          customers.find(
                            customer =>
                              customer.code === creditNote.customerCode
                          )?.name
                        }
                      </td>
                      <td>
                        {moment(new Date(creditNote.date)).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {
                          users.find(user => user._id === creditNote.uploader)
                            ?.name
                        }
                      </td>
                      <td>
                        {creditNote.cancelled ? (
                          <span className="text-danger">Cancelled</span>
                        ) : (
                          <span className="text-secondary">Active</span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Pagination */}
          </>
        ) : (
          <p className="text-muted">No credit notes available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    creditNotes: state.creditNotes.creditNotes,
    totalCreditNotes: state.creditNotes.totalCreditNotes,
    totalPages: state.creditNotes.totalPages,
    customers: state.customer.customers,
    users: state.user.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCreditNotes: data => dispatch(getCreditNotes(data)),
    setUsers: () => dispatch(setUsers()),
    clearNewCreditNote: () => dispatch(clearNewCreditNote()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes)
