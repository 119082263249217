import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Tabs, Tab, Table, Form, Button, Col } from "react-bootstrap"
import { setCustomers, addCustomer } from "../state/actions/customer"
import { setInvoices } from "../state/actions/invoices"
import { getPaymentReceipts } from "../state/actions/paymentReceipts"
import { emailValid } from "../state/utils"
import { navigate } from "gatsby"

// Styles
import styles from "../styles/components/customers.module.scss"
import { FaSearch, FaUserAlt } from "react-icons/fa"

const Customers = ({
  customers,
  setCustomers,
  addCustomer,
  setInvoices,
  getPaymentReceipts,
}) => {
  useEffect(() => {
    setCustomers()
    setInvoices()
    getPaymentReceipts()
  }, [setCustomers])

  const [searchTerm, setSearchTerm] = useState("")

  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [telephone, setTelephone] = useState("")
  const [email, setEmail] = useState("")
  const [vatNumber, setVatNumber] = useState("")
  const [tinNumber, setTinNumber] = useState("")
  const [companyPV_number, setCompanyPV_number] = useState("")
  const [salesPerson, setSalesPerson] = useState("")

  const handleAddCustomer = e => {
    e.preventDefault()
    const customerData = {
      code: code.trim().toUpperCase(),
      name: name.trim(),
      address: address.trim(),
      telephone: telephone.trim(),
      vatNumber: vatNumber?.trim(),
      tinNumber: tinNumber.trim(),
      companyPV_number: companyPV_number.trim(),
      salesPerson: salesPerson.trim(),
    }
    if (email) {
      customerData.email = email
    }
    addCustomer(customerData).then(success => {
      if (success) clearState()
    })
  }

  const clearState = () => {
    setCode("")
    setName("")
    setAddress("")
    setTelephone("")
    setEmail("")
    setVatNumber("")
    setTinNumber("")
    setCompanyPV_number("")
    setSalesPerson("")
  }

  const handleClickCustomer = id => {
    navigate(`/app/edit-customer/${id}`)
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        <FaUserAlt />
        Customers
      </h1>

      <Tabs defaultActiveKey="home" className="mt-5 mb-3">
        <Tab
          eventKey="home"
          title="All Customers"
          tabClassName={styles.tab}
          className="overflow-x-auto"
        >
          {/* Search */}
          <div className={styles.search}>
            <input
              type="text"
              value={searchTerm}
              name="search"
              placeholder="Search"
              autoComplete="off"
              className={styles.searchInput}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
            />
            <FaSearch className={styles.searchIcon} />
          </div>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer Code</th>
                <th>Name</th>
                <th>Address</th>
                <th>Telephone</th>
                <th>Email</th>
                <th>VAT Number</th>
                <th>TIN Number</th>
                <th>Company PV Number</th>
                <th>Sales Person</th>
              </tr>
            </thead>
            <tbody>
              {customers
                .filter(customer => {
                  const search = searchTerm.trim().toLowerCase()
                  var email = ""
                  if (customer.email) {
                    email = customer.email.trim().toLowerCase()
                  }
                  const code = customer.code.trim().toUpperCase()
                  const vatNumber = customer.vatNumber?.trim().toLowerCase()
                  const name = customer.name.trim().toLowerCase()
                  const address = customer.address.trim().toLowerCase()
                  const telephone = customer.telephone.trim().toLowerCase()
                  const salesPerson = customer?.salesPerson
                    ?.trim()
                    .toLowerCase()

                  if (
                    code.includes(search) ||
                    name.includes(search) ||
                    address.includes(search) ||
                    telephone.includes(search) ||
                    vatNumber?.includes(search) ||
                    salesPerson?.includes(search) ||
                    email.includes(search)
                  ) {
                    return customer
                  } else return null
                })
                .sort((a, b) => {
                  return a.code < b.code ? -1 : 1
                })
                .map((customer, idx) => {
                  return (
                    <tr
                      style={{ cursor: "pointer", fontSize: "1.4rem" }}
                      onClick={() => {
                        handleClickCustomer(customer._id)
                      }}
                      key={idx}
                    >
                      <td>{idx + 1}</td>
                      <td>{customer.code}</td>
                      <td>{customer.name}</td>
                      <td>{customer.address}</td>
                      <td>{customer.telephone}</td>
                      <td>{customer.email}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {customer.vatNumber}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {customer.tinNumber}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {customer.companyPV_number}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {customer.salesPerson}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Tab>
        <Tab eventKey="add" title="Add Customer" tabClassName={styles.tab}>
          <Col lg={6} className={styles.addCustomer}>
            <Form className={styles.form}>
              <Form.Group className="form-group">
                <Form.Label>Customer Code</Form.Label>
                <Form.Control
                  value={code}
                  name="code"
                  onChange={e => {
                    setCode(e.target.value)
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  name="name"
                  onChange={e => {
                    setName(e.target.value)
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  value={address}
                  name="address"
                  onChange={e => {
                    setAddress(e.target.value)
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Telephone</Form.Label>
                <Form.Control
                  value={telephone}
                  name="telephone"
                  type="tel"
                  onChange={e => {
                    setTelephone(e.target.value)
                  }}
                  required
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={email}
                  name="email"
                  onChange={e => {
                    setEmail(e.target.value.toLowerCase())
                  }}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>VAT Number</Form.Label>
                <Form.Control
                  value={vatNumber}
                  name="vatNumber"
                  onChange={e => {
                    setVatNumber(e.target.value)
                  }}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>TIN Number</Form.Label>
                <Form.Control
                  value={tinNumber}
                  name="tinNumber"
                  onChange={e => {
                    setTinNumber(e.target.value)
                  }}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Company PV Number</Form.Label>
                <Form.Control
                  value={companyPV_number}
                  name="companyPV_number"
                  onChange={e => {
                    setCompanyPV_number(e.target.value)
                  }}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Sales Person</Form.Label>
                <Form.Control
                  value={salesPerson}
                  name="salesPerson"
                  onChange={e => {
                    setSalesPerson(e.target.value)
                  }}
                />
              </Form.Group>

              <div className="action-buttons">
                <Button
                  className={styles.btn}
                  type="submit"
                  disabled={
                    !code ||
                    !name ||
                    !address ||
                    !telephone ||
                    !salesPerson ||
                    (email && !emailValid(email))
                  }
                  onClick={handleAddCustomer}
                  size="lg"
                  variant="primary"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Col>
        </Tab>
      </Tabs>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCustomers: () => dispatch(setCustomers()),
    addCustomer: data => dispatch(addCustomer(data)),
    setInvoices: () => dispatch(setInvoices()),
    getPaymentReceipts: () => dispatch(getPaymentReceipts()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
