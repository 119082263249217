import { toast } from 'react-toastify'
import axios from 'axios'

export const setUsers = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      const data = await axios.get(
        `${process.env.GATSBY_API_CALL}/users/names`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      dispatch({
        type: 'SET_USERS',
        data: data.data
      })
    } catch (e) {
      console.log(e)
      toast.error(
        `There was an error fetching user data. Please try again later.`
      )
    }
  }
}
