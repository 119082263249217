import React, { useState } from "react"
import * as XLSX from "xlsx"

function FetchExcel({ onDataProcessed }) {
  const handleFileUpload = e => {
    const file = e.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = e => {
      const binaryStr = e.target.result
      const workbook = XLSX.read(binaryStr, { type: "binary" })

      if (!workbook.SheetNames.length) {
        console.error("No sheets found in the Excel file.")
        return
      }

      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const parsedData = XLSX.utils.sheet_to_json(worksheet)

      // Extract "CARTON NO" and "Barcode" and combine them
      const combined = parsedData
        .map(row => {
          const cartonNo = row["CARTON NO"]
          const barcode = row["Barcode"]

          if (cartonNo && barcode) {
            return `${barcode} ${cartonNo}`
          } else {
            console.error(`Missing data in row: ${JSON.stringify(row)}`)
            return ""
          }
        })
        .filter(Boolean) // Remove empty strings
        .join("\n")

      // Pass combined data to parent component
      onDataProcessed(combined)
    }

    reader.onerror = error => {
      console.error("Error reading file:", error)
    }
  }

  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
    </div>
  )
}

export default FetchExcel
