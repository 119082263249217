import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Form, Button, Row, Col } from "react-bootstrap"
import Select from "react-select"
import ProductModal from "../productModal"
import ProductTable from "../productTable"
import {
  setQuoteDate,
  setQuotePaymentMethod,
  setQuoteSalesManager,
  setQuotePaymentTerms,
  setQuoteDeliverTo,
  setQuoteCustomer,
  setQuoteTransport
  
} from "../../state/actions/quotation"
import BackButton from "../backButton"
import PreviewPg from "../previewPg"

import styles from "../../styles/components/quotations/newQuotation.module.scss"

const NewQuotation = ({
  customers,
  quoteData,
  setQuoteDate,
  setQuotePaymentMethod,
  setQuoteSalesManager,
  setQuotePaymentTerms,
  setQuoteDeliverTo,
  setQuoteCustomer,
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const handleSetCustomer = id => {
    const customer = customers.find(c => c._id === id)
    setQuoteCustomer(customer)
  }

  useEffect(() => {
    if (customers.length && Object.keys(quoteData.customer).length === 0) {
      setQuoteCustomer(customers[0])
    }
  }, [])

  const customerOptions = customers.map(customer => {
    return {
      value: customer._id,
      label: `${customer.code} - ${customer.name}`,
    }
  })

  return (
    <div className={styles.wrapper}>
      <h1 className="title">New Quotation</h1>
      {!showPreview && (
        <>
          <BackButton to="/app/quotations" />
          <div className={styles.form}>
            <Row>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    size="lg"
                    value={quoteData.date}
                    name="date"
                    type="date"
                    onChange={e => setQuoteDate(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    name="paymentMethod"
                    value={quoteData.paymentMethod}
                    onChange={e => setQuotePaymentMethod(e.target.value)}
                  >
                    <option>Cash</option>
                    <option>Cheque</option>
                    <option>Bank Transfer</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Sales Manager</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Sales Manager"
                    value={quoteData.salesManager}
                    name="salesManager"
                    onChange={e => setQuoteSalesManager(e.target.value)}
                  />
                </Form.Group>
                {/* <Form.Group className="mt-3">
                  <Form.Label>Transport</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Transport"
                    value={quoteData.transport}
                    name="salesManager"
                    onChange={e => setQuoteTransport(e.target.value)}
                  />
                </Form.Group> */}
                <Form.Group>
                  <Form.Label> Payment Terms</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    onChange={e => setQuotePaymentTerms(e.target.value)}
                    name="paymentTerms"
                    value={quoteData.paymentTerms}
                  >
                    <option>Cash on Delivery</option>
                    <option>Advance Payment</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Delivery Location</Form.Label>
                  <Form.Control
                    size="lg"
                    placeholder="Delivery Location"
                    value={quoteData.deliverTo}
                    name="deliverTo"
                    onChange={e => setQuoteDeliverTo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label>Customer Details</Form.Label>
                  <Select
                    isSearchable={true}
                    defaultValue={
                      customerOptions[
                        customerOptions.findIndex(
                          c => c.value === quoteData.customer._id
                        )
                      ] || customerOptions[0]
                    }
                    options={customerOptions}
                    onChange={e => handleSetCustomer(e.value)}
                  />
                </Form.Group>

                {/* Customer Details */}
                {quoteData.customer && (
                  <>
                    <Row style={{ marginTop: "25px" }}>
                      <Col sm="3">
                        <p>Customer Code</p>
                      </Col>
                      <Col sm="9">
                        <p>{quoteData.customer.code}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <p>Address</p>
                      </Col>
                      <Col sm="9">
                        <p>{quoteData.customer.address}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <p>Telephone</p>
                      </Col>
                      <Col sm="9">
                        <p>{quoteData.customer.telephone}</p>
                      </Col>
                    </Row>
                    {quoteData.customer.email && (
                      <Row>
                        <Col sm="3">
                          <p>Email</p>
                        </Col>
                        <Col sm="9">
                          <p>{quoteData.customer.email}</p>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>

          {/* Product Table */}
          <div className="mt-5">
            <h2 className={styles.productTableHeading}>Products</h2>
            <ProductTable document="quotation" />
            <ProductModal document="quotation" />
          </div>
        </>
      )}

      {/* Generate Preview */}
      {showPreview && <PreviewPg document="quotation" />}
      <Button
        className={styles.btn}
        variant="light"
        onClick={() => {
          setShowPreview(!showPreview)
        }}
      >
        {!showPreview ? `Generate Preview` : `Back to Edit Quotation`}
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
    quoteData: state.quotation.newQuotation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setQuoteDate: date => dispatch(setQuoteDate(date)),
    setQuotePaymentMethod: method => dispatch(setQuotePaymentMethod(method)),
    setQuoteSalesManager: manager => dispatch(setQuoteSalesManager(manager)),
    setQuotePaymentTerms: terms => dispatch(setQuotePaymentTerms(terms)),
    setQuoteDeliverTo: deliverTo => dispatch(setQuoteDeliverTo(deliverTo)),
    setQuoteCustomer: customer => dispatch(setQuoteCustomer(customer)),
    setQuoteTransport: customer => dispatch(setQuoteTransport(customer)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewQuotation)
