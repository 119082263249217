import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { Form, Button, Row, Col } from "react-bootstrap"
import Select from "react-select"
import BackButton from "../backButton"
import InvoiceNumberModal from "./invoiceNumberModal"
import {
  setDate,
  setPaymentMethod,
  setAmount,
  setBank,
  setRemarks,
  setCheque,
  setChequeDate,
  setBankRef,
  setCustomer,
} from "../../state/actions/paymentReceipts"
import { formatNumber, getTotal, vatApplicable } from "../../state/utils"
import PreviewPaymentReceipt from "./previewPaymentReceipt"
import { getAllQuotations } from "../../state/actions/quotation"

import styles from "../../styles/components/paymentReceipts/newPaymentReceipt.module.scss"

const NewPaymentReceipt = ({
  customers,
  receiptData,
  setDate,
  setPaymentMethod,
  setAmount,
  setBank,
  setRemarks,
  setCheque,
  setChequeDate,
  setBankRef,
  setCustomer,
  quotations,
  getAllQuotations,
}) => {
  const [showPreview, setShowPreview] = useState(false)

  const handleSetCustomer = id => {
    const customer = customers.find(c => c._id === id)
    setCustomer(customer)
  }

  useEffect(() => {
    getAllQuotations()

    if (customers.length && Object.keys(receiptData.customer).length === 0) {
      setCustomer(customers[0])
    }
  }, [])

  const customerOptions = customers.map(customer => {
    return {
      value: customer._id,
      label: `${customer?.code} - ${customer?.name}`,
    }
  })

  const updateTotalAmount = total => {
    setAmount(total)
  }

  return (
    <div className={styles.wrapper}>
      <h1 className="title">New Payment Receipt</h1>

      {!showPreview && (
        <>
          <BackButton to="/app/payment-receipts" />
          <Row>
            <Col>
              <Form.Group className="mt-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  size="lg"
                  value={receiptData.date}
                  name="date"
                  type="date"
                  onChange={e => setDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  size="lg"
                  type="number"
                  value={receiptData.amount}
                  onChange={e => setAmount(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Payment Method</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  onChange={e => setPaymentMethod(e.target.value)}
                  value={receiptData.paymentMethod}
                >
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                </Form.Control>
              </Form.Group>
              {(receiptData.paymentMethod === "Cheque" ||
                receiptData.paymentMethod === "Bank Transfer") && (
                <Form.Group className="mt-3">
                  <Form.Label>Bank</Form.Label>
                  <Form.Control
                    as="select"
                    size="lg"
                    onChange={e => setBank(e.target.value)}
                    value={receiptData.bank}
                  >
                    <option value="">Select a Bank</option>
                    <option value="BOC">BOC</option>
                    <option value="COMM">COMM</option>
                    <option value="HNB">HNB</option>
                    <option value="PABC">PABC</option>
                    <option value="NTB">NTB</option>
                    <option value="PEOPLES">PEOPLES</option>
                  </Form.Control>
                </Form.Group>
              )}

              {receiptData.paymentMethod === "Cash" && (
                <>
                  <Form.Group className="mt-3">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      value={receiptData.remarks}
                      onChange={e => setRemarks(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}

              {receiptData.paymentMethod === "Cheque" && (
                <>
                  <Form.Group className="mt-3">
                    <Form.Label>Cheque Number</Form.Label>
                    <Form.Control
                      size="lg"
                      type="number"
                      value={receiptData.chequeNumber}
                      onChange={e => setCheque(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mt-3">
                    <Form.Label>Cheque Date</Form.Label>
                    <Form.Control
                      size="lg"
                      value={receiptData.chequeDate}
                      name="cheque-date"
                      type="date"
                      onChange={e => setChequeDate(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
              {receiptData.paymentMethod === "Bank Transfer" && (
                <Form.Group className="mt-3">
                  <Form.Label>Bank Ref</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={receiptData.bankRef}
                    onChange={e => setBankRef(e.target.value)}
                  />
                </Form.Group>
              )}

              {/* Quotation Numbers */}

              <div className="mt-3">
                <Form.Label className="mr-3">Quotation Numbers</Form.Label>
                <InvoiceNumberModal updateTotalAmount={updateTotalAmount} />
                <div>
                  {receiptData.quotationNumbers.map(quotationNumber => {
                    const quotation = quotations?.find(
                      quote => quote.quotationNumber === quotationNumber
                    )

                    // VAT is 18% for quotations after 2024-01-08

                    const vat = vatApplicable(quotation?.date) ? 1.18 : 1

                    return (
                      <p
                        key={quotationNumber}
                        className="m-0 text-secondary"
                      >{`Quotation Number: ${quotationNumber}, Quotation Total: LKR ${formatNumber(
                        getTotal(quotation?.quotationProducts) * vat
                      )}`}</p>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col>
              <Form.Group className="mt-3">
                <Form.Label>Customer Details</Form.Label>
                <Select
                  isSearchable={true}
                  defaultValue={customerOptions[0]}
                  options={customerOptions}
                  onChange={e => handleSetCustomer(e.value)}
                />
              </Form.Group>

              {/* Customer Details */}
              {receiptData.customer && (
                <>
                  <Row style={{ marginTop: "25px" }}>
                    <Col sm="3">
                      <p>Customer Code</p>
                    </Col>
                    <Col sm="9">
                      <p>{receiptData.customer.code}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Address</p>
                    </Col>
                    <Col sm="9">
                      <p>{receiptData.customer.address}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Telephone</p>
                    </Col>
                    <Col sm="9">
                      <p>{receiptData.customer.telephone}</p>
                    </Col>
                  </Row>
                  {receiptData.customer.email && (
                    <Row>
                      <Col sm="3">
                        <p>Email</p>
                      </Col>
                      <Col sm="9">
                        <p>{receiptData.customer.email}</p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Col>
          </Row>
        </>
      )}

      {/* Preview Payment Receipt */}
      {showPreview && <PreviewPaymentReceipt />}

      {/* Generate Preview Button */}
      <Button
        className={styles.btn}
        variant="light"
        onClick={() => {
          setShowPreview(!showPreview)
        }}
      >
        {!showPreview ? `Generate Preview` : `Back to Edit Payment Receipt`}
      </Button>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
    receiptData: state.paymentReceipts.newPaymentReceipt,
    quotations: state.quotation.quotations,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDate: date => dispatch(setDate(date)),
    setPaymentMethod: method => dispatch(setPaymentMethod(method)),
    setAmount: amount => dispatch(setAmount(amount)),
    setBank: bank => dispatch(setBank(bank)),
    setRemarks: remarks => dispatch(setRemarks(remarks)),
    setCheque: cheque => dispatch(setCheque(cheque)),
    setChequeDate: chequeDate => dispatch(setChequeDate(chequeDate)),
    setBankRef: ref => dispatch(setBankRef(ref)),
    setCustomer: customer => dispatch(setCustomer(customer)),
    getAllQuotations: () => dispatch(getAllQuotations()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaymentReceipt)
