import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import _ from "lodash"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import {
  setInvoiceNumbers,
  setQuotationNumbers,
} from "../../state/actions/paymentReceipts"
import { getAllInvoices } from "../../state/actions/invoices"
import { getAllQuotations } from "../../state/actions/quotation"
import { formatNumber, getTotal, vatApplicable } from "../../state/utils"

import styles from "../../styles/components/paymentReceipts/invoiceNumberModal.module.scss"

const InvoiceNumberModal = ({
  invoices,
  invoiceNumbersInPaymentReceipt,
  setInvoiceNumbers,
  quotations,
  setQuotationNumbers,
  quotationNumbersInPaymentReceipt,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllQuotations())
  }, [dispatch])

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        Add
      </Button>

      {/* Invoice Number Modal */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>Add Quotation Numbers</Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100">
          {/* Search */}
          <div className={styles.search}>
            <input
              type="text"
              value={searchTerm}
              name="search"
              placeholder="Search"
              className={styles.searchInput}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              autoComplete="off"
            />
            <FaSearch className={styles.searchIcon} />
          </div>

          {/* Quotation Numbers */}
          {quotations.length ? (
            <div className={styles.invList}>
              {quotations
                .filter(quotation => {
                  const search = searchTerm.toLowerCase()
                  const quoteNumber = quotation?.quotationNumber?.toLowerCase()

                  if (quoteNumber.includes(search)) {
                    return quotation
                  } else return null
                })

                .sort((a, b) => {
                  if (
                    quotationNumbersInPaymentReceipt.includes(
                      a.quotationNumber
                    ) &&
                    !quotationNumbersInPaymentReceipt.includes(
                      b.quotationNumber
                    )
                  ) {
                    return -1
                  } else if (
                    !quotationNumbersInPaymentReceipt.includes(
                      a.quotationNumber
                    ) &&
                    quotationNumbersInPaymentReceipt.includes(b.quotationNumber)
                  ) {
                    return 1
                  } else {
                    return 0
                  }
                })
                .map(quotation => {
                  // VAT is 18& for quotations after 2024-01-08

                  const vat = vatApplicable(quotation.date) ? 1.18 : 1

                  return (
                    <Form.Group key={quotation.quotationNumber}>
                      <Form.Check
                        className={styles.invoiceCheckbox}
                        type="checkbox"
                        label={`${
                          quotation.quotationNumber
                        } (Quotation Total: LKR ${formatNumber(
                          getTotal(quotation.quotationProducts) * vat
                        )}) `}
                        checked={quotationNumbersInPaymentReceipt.includes(
                          quotation.quotationNumber
                        )}
                        onChange={() =>
                          setQuotationNumbers(quotation.quotationNumber)
                        }
                      />
                    </Form.Group>
                  )
                })}
            </div>
          ) : (
            <div>No Quotation numbers available for this customer</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    invoices: state.invoices.invoices.filter(
      inv =>
        inv.customerCode ===
        state.paymentReceipts.newPaymentReceipt.customer.code
    ),
    quotations: state.quotation.quotations.filter(
      quote =>
        quote.customerCode ===
        state.paymentReceipts.newPaymentReceipt.customer.code
    ),

    invoiceNumbersInPaymentReceipt:
      state.paymentReceipts.newPaymentReceipt.invoiceNumbers,

    quotationNumbersInPaymentReceipt:
      state.paymentReceipts.newPaymentReceipt.quotationNumbers,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setInvoiceNumbers: number => dispatch(setInvoiceNumbers(number)),
    setQuotationNumbers: number => dispatch(setQuotationNumbers(number)),
    getAllQuotations: () => dispatch(getAllQuotations()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceNumberModal)
