import React from 'react'
import { getTotal } from '../../state/utils'
import * as XLSX from 'xlsx'
import { Button } from 'react-bootstrap'
const SalesXlsx = (props) => {
    
  
    const sales = props.arr.map((product)=>{
      

        return{
           Sku: product.sku, InvoiceNo:product.invNo , Quantity:product.quantity , FullCost:product.cost*product.quantity ,SalesPrice:getTotal([product]), Profit: getTotal([product]) - product.cost * product.quantity
          
          }
    })
   

    const downloadXLXX=()=>{
        const workSheet=XLSX.utils.json_to_sheet(sales)
        const workBook=XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workBook,workSheet,"Sales")

        XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
        XLSX.writeFile(workBook,"sales.xlsx")
    }

  return (
    <div onClick={downloadXLXX}>
        <Button   style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "1.5rem",
                  }} >Download XLSX File
        </Button>
    </div>
  )
}

export default SalesXlsx