import React, { useState } from "react"
import Table from "react-bootstrap/Table"
import _ from "lodash"

import { setInvoices } from "../state/actions/invoices"
import { vatApplicable, formatNumber, getTotal } from "../state/utils"
import { connect, dispatch } from "react-redux"

import styles from "../styles/components/documentPreview.module.scss"
import logo from "../images/logo.png"

const DocumentPreview = props => {
  const isVatApplicable = vatApplicable(props.data.date)
  const [productWarranty, setProductWarranty] = useState([])

  const isCancelled = props.data.status

  const productArray =
    props.document === "quotation"
      ? props.data.quotationProducts
      : props.document === "invoice"
      ? props.data.invoiceProducts
      : props.data.creditNoteProducts

  if (props.document === "quotation") {
    productArray.map(product => {
      return {
        ...product,
        cateory: props.product.find(pro => pro.sku == product.sku)?.category,
      }
    })
  }

  const calculateTotalPrice = (
    price,
    qty,
    discount,
    discountType,
    newWarranty
  ) => {
    let discountAmount

    if (newWarranty) {
      discountAmount =
        discountType === "LKR"
          ? discount * qty || 0
          : (price * qty * discount || 0) / 100
    } else {
      discountAmount =
        discountType === "LKR"
          ? discount || 0
          : (price * qty * discount || 0) / 100
    }

    // const addAmountForWarranty=newWarranty && newWarranty == '5 Years' ? (parseFloat(price) * 15/100) * parseFloat(qty) : 0

    if (isNaN(price) || isNaN(qty)) {
      return `Rs. 0`
    } else {
      return `Rs. ${formatNumber(price * qty - discountAmount)}`
    }
  }

  // Warranty for Suntree Cables

  let cableWarranty = []

  if (props.document === "quotation") {
    cableWarranty = props.data.quotationProducts.map(
      prod => prod.category === "DC & AC Cables" && prod.warranty
    )
  } else if (props.document === "invoice") {
    cableWarranty = props.data.invoiceProducts.map(
      prod => prod.category === "DC & AC Cables" && prod.warranty
    )
  }

  return (
    <div className={styles.invoicePreview}>
      <div className={styles.info}>
        <div style={{ maxWidth: "100%" }}>
          {/* Company Info */}
          <div className={styles.logo}>
            <img src={logo} alt="Gunda Power Logo" />
          </div>
          <div className={styles.senderInfo}>
            <p>{props.data.senderAddress}</p>
            <p>{props.data.senderCity}</p>
            {/* To Update */}
            {isVatApplicable && <p>VAT Registration No: 101171507-7000</p>}
            {isVatApplicable && <p>TIN No: 101171507</p>}
            <p>{props.data.senderTel}</p>
            <p>info@gundapower.com / www.gundapower.com</p>
          </div>

          {/* Customer Info */}
          <div className={styles.customerInfo}>
            <p>Bill To:</p>

            <div className={styles.customerDiv}>
              <p>{props.data.customer?.name}</p>
              <p>{props.data.customer?.address}</p>
              <p>{props.data.customer?.telephone}</p>
              <p>{props.data.customer?.email}</p>
            </div>
          </div>
        </div>

        {/* Invoice Info */}
        <div className={styles.invoiceInfo}>
          <h1>
            {props.document === "quotation"
              ? `QUOTATION`
              : `${isVatApplicable ? "TAX" : ""} ${
                  props.document === "creditNote" ? "CREDIT NOTE" : "INVOICE"
                }`}
          </h1>
          <div className={styles.invoiceRow}>
            <div className={styles.cell1}>Date</div>
            <div className={styles.cell2}>{props.data.date}</div>
          </div>
          {props.document !== "creditNote" && (
            <div className={styles.invoiceRow}>
              <div className={styles.cell1}>Payment Method</div>
              <div className={styles.cell2}>{props.data.paymentMethod}</div>
            </div>
          )}
          {/* Quotation Number */}
          {props.data.quotationNumber && (
            <div className={styles.invoiceRow}>
              <div className={styles.cell1}>Quotation Number</div>
              <div className={styles.cell2}>{props.data.quotationNumber}</div>
            </div>
          )}

          {props.document !== "creditNote" && (
            <>
              <div className={styles.invoiceRow}>
                <div className={styles.cell1}>Sales Manager</div>
                <div className={styles.cell2}>{props.data.salesManager}</div>
              </div>
              <div className={styles.invoiceRow}>
                <div className={styles.cell1}>Payment Terms</div>
                <div className={styles.cell2}>{props.data.paymentTerms}</div>
              </div>

              <div className={styles.invoiceRow}>
                <div className={styles.cell1}>Transport</div>
                <div className={styles.cell2}>
                  {props.data.transport && props.data.transport}
                </div>
              </div>

              {props.document === "invoice" && (
                <div className={styles.invoiceRow}>
                  <div className={styles.cell1}>Due Date</div>
                  <div className={styles.cell2}>{props.data.dueDate}</div>
                </div>
              )}
              <div className={styles.invoiceRow}>
                <div className={styles.cell1}>Delivery To</div>
                <div className={styles.cell2}>{props.data.deliverTo}</div>
              </div>
            </>
          )}
          <div className={styles.invoiceRow}>
            <div className={styles.cell1}>Customer Code</div>
            <div className={styles.cell2}>{props.data.customer?.code}</div>
          </div>

          {isVatApplicable && props?.data?.customer?.vatNumber && (
            <div className={styles.invoiceRow}>
              <div className={styles.cell1}>Customer VAT No.</div>
              <div className={styles.cell2}>
                {props.data.customer?.vatNumber}
              </div>
            </div>
          )}

          {props.document === "creditNote" && (
            <div className={styles.invoiceRow}>
              <div className={styles.cell1}>Invoice No.</div>
              <div className={styles.cell2}>{props.data.invoiceNumber}</div>
            </div>
          )}
        </div>
      </div>

      {/* Item Info */}
      <div className={styles.itemTable}>
        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Item Description</th>
              <th>Image</th>
              <th>Qty.</th>
              <th>Unit Price</th>
              {props.document !== "creditNote" && <th>Disc.</th>}
              {props.document !== "creditNote" && <th>Total Disc.</th>}
              <th>Amount (LKR)</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productArray.map((product, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>
                  <p>{product.productName}</p>
                  {product.productDescription !== "N/A" && (
                    <p>{product.productDescription}</p>
                  )}
                  <p>{product.sku}</p>
                </td>
                <td>
                  {product.productImages && product.productImages[0] ? (
                    <img
                      src={product.productImages[0].fields.file.url}
                      className={styles.itemImg}
                      alt={`${product.productName}`}
                    />
                  ) : (
                    <p>N/A</p>
                  )}
                </td>
                <td>{product.quantity}</td>
                <td>Rs. {formatNumber(product.price)}</td>
                {props.document !== "creditNote" && (
                  <>
                    <td>
                      {product.discount === 0
                        ? "-"
                        : product.discountType === "%"
                        ? `${product.discount}%`
                        : `Rs.${formatNumber(product.discount)}`}
                    </td>
                    <td>
                      {product.discount === 0
                        ? "-"
                        : product.discountType === "%"
                        ? `Rs. ${formatNumber(
                            product.price *
                              product.quantity *
                              (Number(product.discount) / 100)
                          )}`
                        : `Rs. ${formatNumber(
                            product.discount * product.quantity
                          )}`}
                    </td>
                  </>
                )}
                <td>
                  {calculateTotalPrice(
                    product.price,
                    product.quantity,
                    product.discount,
                    product.discountType,
                    product.newWarranty
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          {productArray.length > 0 && (
            <tfoot>
              {
                // If date is past 8th January 2024, add 18% VAT
                isVatApplicable && !isCancelled ? (
                  <>
                    <tr>
                      <td
                        colSpan={`${
                          props.document === "creditNote" ? "5" : "7"
                        }`}
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Subtotal
                      </td>
                      <td>Rs. {formatNumber(getTotal(productArray))}</td>
                    </tr>

                    <tr>
                      <td
                        colSpan={`${
                          props.document === "creditNote" ? "5" : "7"
                        }`}
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        18% VAT
                      </td>
                      <td>
                        {/* 18% VAT */}
                        Rs. {formatNumber(getTotal(productArray) * 0.18)}
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={`${
                          props.document === "creditNote" ? "5" : "7"
                        }`}
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        Total
                      </td>
                      <td>
                        {/* 18% VAT */}
                        Rs. {formatNumber(getTotal(productArray) * 1.18)}
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      Total
                    </td>
                    <td>
                      Rs.{" "}
                      {isCancelled ? 0 : formatNumber(getTotal(productArray))}
                    </td>
                  </tr>
                )
              }
            </tfoot>
          )}
        </Table>
      </div>

      {/* Terms and Conditions */}
      <div className={styles.terms}>
        {props.document !== "creditNote" && (
          <>
            {props.document === "quotation" && (
              <div>
                {/* Warranty */}
                <h6 className={styles.conditionTitle}>
                  Terms and Conditions: Warranty
                </h6>

                <p>* Warranty covers manufacturing defects only.</p>
                <p>
                  * Invoice / Warranty card must be produced for warranty
                  claims.
                </p>
                <p>
                  * For warranty claims, contact Gunda Power (Pvt) Ltd on 0777
                  275 626 / 0112872395
                </p>
                <p>
                  * Good sold are not refundable / returned or exchangeable
                  under any circumstances.
                </p>
                <p>
                  * Wear and tear on wiring, scratches, stains, etc..that do not
                  affect the function of goods, wiring &amp; other accessories
                  on equipment cannot be claimed under warranty.
                </p>
                <p>
                  * Natural perils, misuse of equipment, burnt marks, repairs
                  made by 3rd party other than Gunda Power (Pvt) Ltd. and any
                  tampering or misuse of equipment renders the warranty void.
                </p>
                <p>
                  * Installers and End Consumers are advised to insure the Solar
                  System under "General Insurance Policy", as under no
                  circumstances will Gunda Power (Pvt) Ltd be liable for
                  consequential, special, direct/indirect, incidental, punitive
                  damages, losses or expenses occurred in any such situations.
                </p>
                <p>
                  * Anyone installing batteries that are not compatible with
                  Growatt will take the risk of warranty not being honored by
                  the manufacturer company. Similarly we Gunda Power will also
                  not issue replacements if manufacturer does not advice us to
                  offer this.
                </p>
                {cableWarranty[0] && (
                  <p>* Suntree DC & AC cables - {cableWarranty[0]}</p>
                )}
              </div>
            )}

            {/* Payment */}
            <div style={{ marginTop: "10px" }}>
              <h6 className={styles.conditionTitle}>
                Terms and Conditions: Payment
              </h6>
              {props.document === "quotation" && (
                <p>* This quotation is only valid for 3 days.</p>
              )}
              <p>* Cheques should be made payable to - Gunda Power (Pvt) Ltd</p>
              <p>
                * Forward scanned copy of direct bank deposit slip to :
                info@gundapower.com
              </p>
              <p>
                * For any querries contact our Accountnatnt on email -
                info@gundapower.com
              </p>
              <p>
                * Delayed / Overdue payment will be subject to 3% per month on
                outstanding balances
              </p>
              <p>
                * Price on items may change according to International Foreign
                Currency Exchange Rates
              </p>
            </div>

            {/* Banking Information */}
            <div style={{ marginTop: "10px" }}>
              <h6 className={styles.conditionTitle}>Banking Information</h6>
              <p>
                * Gunda Power (Pvt) Ltd. - Pan Asia Banking Corporation PLC -
                Borella Branch - 103111002175
              </p>
              <p>
                * Gunda Power (Pvt) Ltd. - Bank of Ceylon - Rajagiriya Branch -
                0085933705
              </p>
              <p>
                * Gunda Power (Pvt) Ltd. - Hatton National Bank - Head Office
                Branch - 003010544539
              </p>
              <p>
                * Gunda Power (Pvt) Ltd. - Commercial Bank of Ceylon PLC -
                Foreign Branch - 1000367087
              </p>
              {/* <p>
            * Gunda Power (Pvt) Ltd. - Bank of China - Colombo Branch -
            100002800008651
          </p> */}
            </div>
          </>
        )}
        <div className={styles.footer}>
          This is a system generated invoice. Signature is not required.
        </div>

        {/* Serial Numbers */}
        {props.document === "invoice" &&
          productArray.some(
            product => product.serialNumbersInInvoice?.length !== 0
          ) && (
            <div className="mt-3">
              <h3>Serial Numbers</h3>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Serial Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {productArray.map((product, idx) => {
                    if (
                      !product.serialNumbersInInvoice ||
                      product.serialNumbersInInvoice?.length === 0
                    )
                      return

                    return (
                      <tr key={idx}>
                        <td>
                          <p>{product.productName}</p>
                          {product.productDescription !== "N/A" && (
                            <p>{product.productDescription}</p>
                          )}
                          <p>{product.sku}</p>
                        </td>
                        <td>
                          {product.serialNumbersInInvoice.map(serial => (
                            <span
                              key={serial.main}
                              className="mr-3"
                              style={{ backgroundColor: "#eee" }}
                            >
                              {serial.main}{" "}
                              {product.category === "Solar Inverter" &&
                                serial.complimentary &&
                                `(WiFi Stick Serial: ${serial.complimentary})`}
                            </span>
                          ))}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )}

        {props.document === "creditNote" &&
          productArray.some(
            product => product.serialNumbersInCreditNote?.length !== 0
          ) && (
            <div className="mt-3">
              <h3>Serial Numbers</h3>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Serial Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {productArray.map((product, idx) => {
                    if (
                      !product.serialNumbersInCreditNote ||
                      product.serialNumbersInCreditNote?.length === 0
                    )
                      return

                    return (
                      <tr key={idx}>
                        <td>
                          <p>{product.productName}</p>
                          {product.productDescription !== "N/A" && (
                            <p>{product.productDescription}</p>
                          )}
                          <p>{product.sku}</p>
                        </td>
                        <td>
                          {product.serialNumbersInCreditNote.map(serial => (
                            <span
                              key={serial.main}
                              className="mr-3"
                              style={{ backgroundColor: "#eee" }}
                            >
                              {serial.main}{" "}
                              {product.category === "Solar Inverter" &&
                                serial.complimentary &&
                                `(WiFi Stick Serial: ${serial.complimentary})`}
                            </span>
                          ))}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          )}
      </div>
    </div>
  )
}
const mapStateToProps = (state, props) => {
  return {
    product: state.product.products,
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreview)
