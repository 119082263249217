import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Button, Table } from "react-bootstrap"
import { HiOutlineDocumentAdd } from "react-icons/hi"
import { navigate } from "gatsby"
import moment from "moment"
import { getQuotations, clearNewQuotation } from "../../state/actions/quotation"
import { setUsers } from "../../state/actions/user"
import {
  FaFileInvoiceDollar,
  FaArrowLeft,
  FaArrowRight,
  FaSearch,
} from "react-icons/fa"
import Loading from "../loading"

import styles from "../../styles/components/quotations/quotations.module.scss"

const Quotations = ({
  quotations,
  totalQuotations,
  totalPages,
  customers,
  users,
  getQuotations,
  setUsers,
  clearNewQuotation,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")

  const [value, setValue] = useState("")

  useEffect(() => {
    setUsers()
    getQuotations({
      skip: currentPage,
      limit: itemsPerPage,
      search: searchTerm, // Pass search term to getQuotations
    })
  }, [currentPage, itemsPerPage, searchTerm])

  if (!quotations.length && !searchTerm)
    return <Loading text="Loading Quotations .." />

  const onSearch = searchName => {
    setSearchTerm(searchName)
    setValue(searchName)
    setCurrentPage(1)
  }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="title">
          <FaFileInvoiceDollar />
          Quotations
        </h1>
        <div className="action-buttons">
          <Button
            size="lg"
            variant="success"
            onClick={() => {
              clearNewQuotation()
              navigate("/app/quotations/new")
            }}
          >
            <HiOutlineDocumentAdd
              className="mb-1 mr-2"
              style={{ fontSize: "16px" }}
            />
            New Quotation
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {/* Search */}
        <div className={styles.search}>
          <input
            type="text"
            value={value}
            name="search"
            placeholder="Search"
            autoComplete="off"
            className={styles.searchInput}
            onChange={e => {
              setValue(e.target.value)
              setSearchTerm(e.target.value)
              setCurrentPage(1)
            }}
          />

          <FaSearch className={styles.searchIcon} />
        </div>

        <ul className={styles.dropdown}>
          {customers
            .filter(customer => {
              const searchName = value.toLowerCase()
              const customerName = customer.name.toLowerCase()

              return (
                searchName &&
                customerName.includes(searchName) &&
                customerName !== searchName
              )
            })
            .map(customer => (
              <div
                className={styles.dropdownRow}
                onClick={() => onSearch(customer?.name)}
              >
                {customer?.name}
              </div>
            ))}
        </ul>

        {quotations.length ? (
          <>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Quotation Number</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Created By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {quotations?.map(quotation => (
                  <tr
                    key={quotation._id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/app/quotations/${quotation._id}`)
                    }}
                  >
                    <td>{quotation.quotationNumber}</td>
                    <td>
                      {quotation.customerCode} -{" "}
                      {
                        customers.find(
                          customer => customer.code === quotation.customerCode
                        )?.name
                      }
                    </td>
                    <td>
                      {moment(new Date(quotation.date)).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {
                        users.find(user => user._id === quotation.uploader)
                          ?.name
                      }
                    </td>
                    <td>
                      {quotation.cancelled ? (
                        <span className="text-danger">Cancelled</span>
                      ) : quotation.paymentReceived ? (
                        <span className="text-success">Payment Received</span>
                      ) : quotation.creditConfirmation ? (
                        <span className="text-success">Credit Confirmed</span>
                      ) : (
                        <span className="text-secondary">Active</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <Row className="d-flex justify-content-center align-items-center mx-4 my-3">
              <div className=" d-flex align-items-center mx-4 my-4 my-lg-0">
                {/* Dropdown to change items per page */}
                <h3
                  className="small d-inline mr-2"
                  style={{
                    minWidth: "105px",
                  }}
                >
                  Items per page:
                </h3>
                <select
                  className="form-control form-control-lg"
                  value={itemsPerPage}
                  onChange={e => {
                    setCurrentPage(1)
                    setItemsPerPage(e.target.value)
                  }}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <Button
                size="lg"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                variant={currentPage === 1 ? "secondary" : "primary"}
              >
                <FaArrowLeft />
              </Button>

              <span className="mx-3 small">
                Page {currentPage} /{" "}
                {
                  // Total pages
                  totalPages
                }
              </span>

              <Button
                size="lg"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage >= totalQuotations / itemsPerPage}
              >
                <FaArrowRight />
              </Button>
            </Row>
          </>
        ) : (
          <p className="text-muted">No quotations available!</p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    quotations: state.quotation.quotations,
    totalQuotations: state.quotation.quotationTotal,
    totalPages: state.quotation.totalPages,
    customers: state.customer.customers,
    users: state.user.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getQuotations: data => dispatch(getQuotations(data)),
    setUsers: () => dispatch(setUsers()),

    // Clear New Quotation
    clearNewQuotation: () => dispatch(clearNewQuotation()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quotations)
