import React from "react"
import { Bar } from "react-chartjs-2"

const StockChart = ({ data }) => {
  // Extract the product names and quantities from the data
  const productNames = data.map(item => item.productName)

  const productQuantities = data.map(item => item.quantity)

  const chartData = {
    labels: productNames,
    datasets: [
      {
        label: "Product Quantity",
        data: productQuantities,
        backgroundColor: "#36A2EB",
      },
    ],
  }

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        precision: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
  }

  return (
    <div className="w-100 mt-5 mb-3">
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default StockChart
