import React, { useState, useEffect } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import logo from "../images/logo.png"
import _ from "lodash"
import { Button } from "react-bootstrap"
import { formatNumber, getTotal, vatApplicable } from "../state/utils"

const PDFGenerator = props => {
  const isVatApplicable = vatApplicable(props.data.date)
  const vatNumber = (isVatApplicable && props.data.customer?.vatNumber) || "-"

  const [isClient, setClient] = useState(false)
  const [showDownloadLink, setShowDownloadLink] = useState(false)

  const productArray =
    props.document === "quotation"
      ? props.data.quotationProducts
      : props.document === "invoice"
      ? props.data.invoiceProducts
      : props.data.creditNoteProducts

  const hasDiscount = productArray.some(product => product.discount !== 0)

  const calculateTotalPrice = (
    price,
    qty,
    discount,
    discountType,
    newWarranty
  ) => {
    let discountAmount

    if (newWarranty) {
      discountAmount =
        discountType === "LKR"
          ? discount * qty || 0
          : (price * qty * discount || 0) / 100
    } else {
      discountAmount =
        discountType === "LKR"
          ? discount || 0
          : (price * qty * discount || 0) / 100
    }

    // const addAmountForWarranty=newWarranty && newWarranty == '5 Years' ? (parseFloat(price) * 15/100) * parseFloat(qty) : 0

    if (isNaN(price) || isNaN(qty)) {
      return `Rs. 0`
    } else {
      return `${formatNumber(price * qty - discountAmount)}`
    }
  }

  // const [xx,setX]=useState([])
  // setX(productArray)

  useEffect(() => {
    setClient(true)
    // Set the showDownloadLink to true after the initial render
    setShowDownloadLink(true)
  }, [])

  // Warranty for Suntree Cables

  let cableWarranty = []

  if (props.document === "quotation") {
    cableWarranty = props.data.quotationProducts.map(
      prod => prod.category === "DC & AC Cables" && prod.warranty
    )
  } else if (props.document === "invoice") {
    cableWarranty = props.data.invoiceProducts.map(
      prod => prod.category === "DC & AC Cables" && prod.warranty
    )
  }

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} />
        </View>

        {/* <View style={styles.fixedHeader} fixed></View> */}

        {/* Sender & Receiver Info */}
        <View style={styles.infoHeader}>
          <View>
            <View style={styles.sender}>
              <Text>{props.data.senderAddress}</Text>
              <Text>{props.data.senderCity}</Text>
              {/* To Update */}
              {isVatApplicable && (
                <Text>VAT Registration No: 101171507-7000</Text>
              )}
              {isVatApplicable && <Text>TIN No: 101171507</Text>}
              <Text>{props.data.senderTel}</Text>
              <Text>info@gundapower.com / www.gundapower.com</Text>
            </View>

            <View style={styles.customer}>
              <Text
                style={{
                  fontSize: 9,
                  marginTop: 15,
                  marginBottom: 5,
                }}
              >
                Bill To:
              </Text>
              <View style={styles.customerInfo}>
                <Text>{props.data.customer?.name}</Text>
                <Text>{props.data.customer?.address}</Text>
                <Text>{props.data.customer?.telephone}</Text>
                <Text>{props.data.customer?.email}</Text>
              </View>
            </View>
          </View>

          {/* Invoice Info */}
          <View style={{ marginTop: -40 }}>
            <Text style={{ fontWeight: 800, marginBottom: 5, fontSize: 15 }}>
              {props.document === "quotation"
                ? `QUOTATION`
                : `${isVatApplicable ? "TAX" : ""} ${
                    props.document === "invoice" ? "INVOICE" : "CREDIT NOTE"
                  } `}
            </Text>
            <View style={styles.row}>
              <Text style={styles.cell1}>Date</Text>
              <Text style={styles.cell2}>{props.data?.date}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>
                {props?.document === "quotation"
                  ? `Quotation No.`
                  : props?.document === "invoice"
                  ? `Invoice No.`
                  : "Credit Note No."}
              </Text>
              <Text style={styles.cell2}>{props.data.documentNumber}</Text>
            </View>
            {props?.document === "invoice" &&
              props?.data?.quotationNumber !== "" && (
                <View style={styles.row}>
                  <Text style={styles.cell1}>Quotation No.</Text>
                  <Text style={styles.cell2}>
                    {props?.data?.quotationNumber}
                  </Text>
                </View>
              )}

            {props?.document === "creditNote" &&
              props?.data?.invoiceNumber !== "" && (
                <View style={styles.row}>
                  <Text style={styles.cell1}>Invoice No.</Text>
                  <Text style={styles.cell2}>{props?.data?.invoiceNumber}</Text>
                </View>
              )}

            {props.document !== "creditNote" && (
              <>
                <View style={styles.row}>
                  <Text style={styles.cell1}>Payment Method</Text>
                  <Text style={styles.cell2}>{props.data.paymentMethod}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.cell1}>Sales Manager</Text>
                  <Text style={styles.cell2}>{props.data.salesManager}</Text>
                </View>

                <View style={styles.row}>
                  <Text style={styles.cell1}>Payment Terms</Text>
                  <Text style={styles.cell2}>{props.data.paymentTerms}</Text>
                </View>
              </>
            )}
            {props.document === "invoice" && (
              <View style={styles.row}>
                <Text style={styles.cell1}>Due Date</Text>
                <Text style={styles.cell2}>{props.data.dueDate}</Text>
              </View>
            )}
            {props.document !== "creditNote" && (
              <View style={styles.row}>
                <Text style={styles.cell1}>Delivery To</Text>
                <Text style={styles.cell2}> {props.data.deliverTo}</Text>
              </View>
            )}
            <View style={[styles.row]}>
              <Text style={styles.cell1}>Customer Code</Text>
              <Text style={styles.cell2}>{props.data.customer?.code}</Text>
            </View>

            <View style={[styles.row]}>
              <Text style={styles.cell1}>Customer VAT No.</Text>
              <Text style={styles.cell2}>{vatNumber}</Text>
            </View>
          </View>
        </View>

        {/* Main Part */}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell, styles.col1]}>#</Text>
            <Text style={[styles.headerCell, styles.col2]}>
              Item Description
            </Text>
            {props.document === "quotation" && (
              <Text style={[styles.headerCell, styles.col3]}>Image</Text>
            )}
            <Text style={[styles.headerCell, styles.col4]}>Qty.</Text>
            <Text style={[styles.headerCell, styles.col5]}>
              Unit Price (LKR)
            </Text>
            {/* <Text style={[styles.headerCell, styles.col6]}>Disc.</Text> */}
            {props.document !== "creditNote" && hasDiscount && (
              <Text style={[styles.headerCell, styles.col7]}>
                Total Discount (LKR)
              </Text>
            )}
            <Text
              style={[styles.headerCell, styles.col8, { borderRight: false }]}
            >
              Amount (LKR)
            </Text>
          </View>
          {productArray.map((product, idx) => {
            return (
              <View style={styles.tableRow} wrap={false} key={idx}>
                <Text style={[styles.tableCell, styles.col1]}>{idx + 1}</Text>

                <View style={[styles.tableCell, styles.col2]}>
                  <Text>{product.productName}</Text>
                  {product.productDescription !== "N/A" && (
                    <Text>{product.productDescription}</Text>
                  )}
                  <Text>{product.sku}</Text>
                  <Text>
                    {product.category?.includes("Inverter") &&
                    product.newWarranty ? (
                      <Text>Warranty Period - {product.newWarranty}</Text>
                    ) : (
                      <Text>
                        {product.warranty &&
                          `Warranty Period - ${product.warranty}`}
                      </Text>
                    )}
                  </Text>
                </View>

                {/* product image */}

                {props.document === "quotation" && (
                  <View style={[styles.tableCell, styles.col3]}>
                    {product.productImages && product.productImages[0] ? (
                      <Image
                        src={product.productImages[0].fields.file.url}
                        style={{
                          maxWidth: "80px",
                          maxHeight: "80px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Text>N/A</Text>
                    )}
                  </View>
                )}

                <Text style={[styles.tableCell, styles.col4]}>
                  {product.quantity}
                </Text>
                <Text style={[styles.tableCell, styles.col5]}>
                  {formatNumber(product.price)}
                </Text>
                {/* <Text style={[styles.tableCell, styles.col6]}>
                  {product.discount === 0
                    ? '-'
                    : product.discountType === '%'
                    ? `${product.discount}%`
                    : formatNumber(product.discount)}
                </Text> */}
                {props.document !== "creditNote" && hasDiscount && (
                  <Text style={[styles.tableCell, styles.col7]}>
                    {product.discount === 0
                      ? "-"
                      : product.discountType === "%"
                      ? formatNumber(
                          product.price *
                            product.quantity *
                            (Number(product.discount) / 100)
                        )
                      : formatNumber(product.discount * product.quantity)}
                  </Text>
                )}
                <Text style={[styles.tableCell, styles.col8]}>
                  {calculateTotalPrice(
                    product.price,
                    product.quantity,
                    product.discount,
                    product.discountType,
                    product.newWarranty
                  )}
                </Text>
              </View>
            )
          })}

          {productArray.length > 0 && (
            <>
              {/* Add VAT if date is 2024 */}
              {props.data.date && isVatApplicable ? (
                <>
                  <View style={styles.tableRow} wrap={false}>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col6,
                        {
                          textAlign: "right",
                          width: "75%",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: "11",
                        },
                      ]}
                    >
                      Subtotal
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col8,
                        { borderLeft: false, fontSize: "11" },
                      ]}
                    >
                      Rs. {formatNumber(getTotal(productArray))}
                    </Text>
                  </View>
                  <View style={styles.tableRow} wrap={false}>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col6,
                        {
                          textAlign: "right",
                          width: "75%",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: "11",
                        },
                      ]}
                    >
                      18% VAT
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col8,
                        { borderLeft: false, fontSize: "11" },
                      ]}
                    >
                      Rs. {formatNumber(getTotal(productArray) * 0.18)}
                    </Text>
                  </View>
                  <View style={styles.tableRow} wrap={false}>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col6,
                        {
                          textAlign: "right",
                          width: "75%",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          fontSize: "11",
                        },
                      ]}
                    >
                      Total
                    </Text>
                    <Text
                      style={[
                        styles.tableCell,
                        styles.col8,
                        { borderLeft: false, fontSize: "11" },
                      ]}
                    >
                      Rs. {formatNumber(getTotal(productArray) * 1.18)}
                    </Text>
                  </View>
                </>
              ) : (
                <View style={styles.tableRow} wrap={false}>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.col6,
                      {
                        textAlign: "right",
                        width: "75%",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        fontSize: "11",
                      },
                    ]}
                  >
                    Total
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      styles.col8,
                      { borderLeft: false, fontSize: "11" },
                    ]}
                  >
                    Rs. {formatNumber(getTotal(productArray))}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>

        {/* Conditions & Notes */}
        <View style={styles.notes}>
          {props.document !== "creditNote" && (
            <>
              {props.document === "quotation" && (
                <View wrap={false}>
                  {/* Warranty */}
                  <Text style={styles.h2}>Terms and Conditions: Warranty</Text>

                  <Text>* Warranty covers manufacturing defects only.</Text>
                  <Text style={styles.notesText}>
                    * Invoice / Warranty card must be produced for warranty
                    claims.
                  </Text>
                  <Text style={styles.notesText}>
                    * For warranty claims, contact Gunda Power (Pvt) Ltd on 0777
                    275 626 / 0112872395
                  </Text>
                  <Text style={styles.notesText}>
                    * Good sold are not refundable / returned or exchangeable
                    under any circumstances.
                  </Text>

                  <Text style={styles.notesText}>
                    * Wear and tear on wiring, scratches, stains, etc..that do
                    not affect the function of goods, wiring &amp; other
                    accessories on equipment cannot be claimed under warranty.
                  </Text>
                  <Text style={styles.notesText}>
                    * Natural perils, misuse of equipment, burnt marks, repairs
                    made by 3rd party other than Gunda Power (Pvt) Ltd. and any
                    tampering or misuse of equipment renders the warranty void.
                  </Text>
                  <Text style={styles.notesText}>
                    * Installers and End Consumers are advised to insure the
                    Solar System under "General Insurance Policy", as under no
                    circumstances will Gunda Power (Pvt) Ltd be liable for
                    consequential, special, direct/indirect, incidental,
                    punitive damages, losses or expenses occurred in any such
                    situations.
                  </Text>
                  <Text style={styles.notesText}>
                    * Anyone installing batteries that are not compatible with
                    Growatt will take the risk of warranty not being honored by
                    the manufacturer company. Similarly we Gunda Power will also
                    not issue replacements if Manufacturer does not advice us to
                    offer this.
                  </Text>

                  {cableWarranty[0] && (
                    <Text style={styles.notesText}>
                      * Suntree DC & AC cables - {cableWarranty[0]}
                    </Text>
                  )}
                </View>
              )}

              {/* Payment Conditions */}
              <View wrap={false}>
                <Text style={[styles.h2, { marginTop: 10 }]}>
                  Terms and Conditions: Payment
                </Text>
                {props.document === "quotation" && (
                  <Text style={styles.notesText}>
                    * This quotation is only valid for 3 days.
                  </Text>
                )}
                <Text style={styles.notesText}>
                  * Cheques should be made payable to - Gunda Power (Pvt) Ltd
                </Text>
                <Text style={styles.notesText}>
                  * Forward scanned copy of direct bank deposit slip to :
                  info@gundapower.com
                </Text>
                <Text style={styles.notesText}>
                  * For any querries contact our Accountnatnt on email -
                  info@gundapower.com
                </Text>
                <Text style={styles.notesText}>
                  * Delayed / Overdue payment will be subject to 3% per month on
                  outstanding balances
                </Text>
                <Text style={styles.notesText}>
                  * Price on items may change according to International Foreign
                  Currency Exchange Rates
                </Text>
              </View>

              {/* Banking Info */}
              <View wrap={false}>
                <Text style={[styles.h2, { marginTop: 10 }]}>
                  Banking Information
                </Text>
                <Text style={styles.notesText}>
                  * Gunda Power (Pvt) Ltd. - Pan Asia Banking Corporation PLC -
                  Borella Branch -103111002175
                </Text>
                <Text style={styles.notesText}>
                  * Gunda Power (Pvt) Ltd. - Bank of Ceylon - Rajagiriya Branch
                  - 0085933705
                </Text>
                <Text style={styles.notesText}>
                  * Gunda Power (Pvt) Ltd. - Hatton National Bank - Head Office
                  Branch - 003010544539
                </Text>
                <Text style={styles.notesText}>
                  * Gunda Power (Pvt) Ltd. - Commercial Bank of Ceylon PLC -
                  Foreign Branch - 1000367087
                </Text>
                {/* <Text style={styles.notesText}>
              * Gunda Power (Pvt) Ltd. - Bank of China - Colombo Branch -
              100002800008651
            </Text> */}

                <Text style={styles.footer} fixed>
                  This is a system generated invoice. Signature is not required.
                </Text>
              </View>
            </>
          )}
        </View>

        {/* Page Number */}
        <Text
          style={styles.pageNumber}
          fixed
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>

      {/* Serial Number Page */}
      {props.document === "invoice" &&
        productArray.some(
          product => product.serialNumbersInInvoice?.length !== 0
        ) && (
          <Page size="A4" style={styles.serialPage} wrap>
            <Text style={styles.serialTitle}>
              Serial Numbers ({props.data.documentNumber})
            </Text>
            <View style={styles.serialTable}>
              <View style={styles.serialRow}>
                <Text style={styles.serialCell1}>Item</Text>
                <Text style={styles.serialCell2}>Serial Numbers</Text>
              </View>
              {productArray.map((product, idx) => {
                if (
                  !product.serialNumbersInInvoice ||
                  product.serialNumbersInInvoice.length === 0
                )
                  return

                return (
                  <View style={styles.serialRow} key={idx}>
                    <View style={styles.serialCell1}>
                      <Text>{product.productName}</Text>
                      {product.productDescription !== "N/A" && (
                        <Text>{product.productDescription}</Text>
                      )}
                      <Text>{product.sku}</Text>
                    </View>
                    <View style={styles.serialCell2}>
                      {product.serialNumbersInInvoice.map((serial, idx) => (
                        <Text
                          key={idx}
                          style={{
                            backgroundColor: "#eee",
                            marginRight: 7,
                            fontSize: 10,
                          }}
                        >
                          {serial.main}{" "}
                          {product.category === "Solar Inverter" &&
                            serial.complimentary &&
                            `(WiFi Stick Serial: ${serial.complimentary})`}
                        </Text>
                      ))}
                    </View>
                  </View>
                )
              })}
            </View>

            {/* Page Number */}
            <Text
              style={styles.pageNumber}
              fixed
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </Page>
        )}

      {props.document === "creditNote" &&
        productArray.some(
          product => product.serialNumbersInCreditNote?.length !== 0
        ) && (
          <Page size="A4" style={styles.serialPage} wrap>
            <Text style={styles.serialTitle}>
              Serial Numbers ({props.data.documentNumber})
            </Text>
            <View style={styles.serialTable}>
              <View style={styles.serialRow}>
                <Text style={styles.serialCell1}>Item</Text>
                <Text style={styles.serialCell2}>Serial Numbers</Text>
              </View>
              {productArray.map((product, idx) => {
                if (
                  !product.serialNumbersInCreditNote ||
                  product.serialNumbersInCreditNote.length === 0
                )
                  return

                return (
                  <View style={styles.serialRow} key={idx}>
                    <View style={styles.serialCell1}>
                      <Text>{product.productName}</Text>
                      {product.productDescription !== "N/A" && (
                        <Text>{product.productDescription}</Text>
                      )}
                      <Text>{product.sku}</Text>
                    </View>
                    <View style={styles.serialCell2}>
                      {product.serialNumbersInCreditNote.map((serial, idx) => (
                        <Text
                          key={idx}
                          style={{
                            backgroundColor: "#eee",
                            marginRight: 7,
                            fontSize: 10,
                          }}
                        >
                          {serial.main}{" "}
                          {product.category === "Solar Inverter" &&
                            serial.complimentary &&
                            `(WiFi Stick Serial: ${serial.complimentary})`}
                        </Text>
                      ))}
                    </View>
                  </View>
                )
              })}
            </View>

            {/* Page Number */}
            <Text
              style={styles.pageNumber}
              fixed
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </Page>
        )}
    </Document>
  )

  return (
    <div>
      {/* Render PDFDownloadLink only when the showDownloadLink is true */}
      {isClient && showDownloadLink && (
        <div>
          <PDFDownloadLink
            document={<MyDocument />}
            fileName={`${props.data.documentNumber} ${props.data.customer?.name}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <p
                  style={{
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  Loading Download
                </p>
              ) : (
                <p
                  style={{
                    textTransform: "uppercase",
                    fontFamily: "Montserrat",
                    color: "#126b91",
                    fontWeight: "bold",
                  }}
                >
                  <Button
                    style={{
                      width: "100%",
                      height: "48px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Download{" "}
                    {props.document === "quotation"
                      ? "Quotation"
                      : props.document === "invoice"
                      ? "Invoice"
                      : "Credit Note"}
                  </Button>
                </p>
              )
            }
          </PDFDownloadLink>
        </div>
      )}
    </div>
  )
}

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    marginHorizontal: 30,
    fontSize: 12,
    paddingTop: 40,
    paddingBottom: 10,
  },
  header: {
    width: "25%",
    marginBottom: 20,
  },
  fixedHeader: {
    marginTop: 40,
  },

  customerInfo: {
    border: true,
    borderRadius: 3,
    padding: 10,
    maxWidth: 200,
  },
  row: {
    flexDirection: "row",
    padding: 2,
    border: true,
    marginTop: -1,
  },
  cell1: {
    paddingRight: 10,
    fontSize: 9,
  },
  cell2: {
    paddingLeft: 40,
    marginLeft: "auto",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    width: "90%",
  },
  sender: {
    fontSize: 10,
  },
  h2: {
    fontWeight: "bold",
    textDecoration: "underline",
    marginBottom: 5,
  },
  notes: {
    marginTop: 15,
    fontSize: 8,
  },
  notesText: {
    maxWidth: "90%",
  },
  pageNumber: {
    position: "absolute",
    right: "60px",
    top: "30px",
    fontSize: 9,
    color: "grey",
  },

  table: {
    marginTop: 15,
    width: "90%",
  },
  col1: {
    width: "4%",
  },
  col2: {
    width: "26%",
  },
  col3: {
    width: "16%",
  },
  col4: {
    width: "7%",
  },
  col5: {
    width: "15%",
  },
  // col6: {
  //   width: '12%'
  // },
  col7: {
    width: "17%",
  },
  col8: {
    width: "22%",
  },
  tableHeader: {
    flexDirection: "row",
    borderTop: true,
    borderBottom: true,
    backgroundColor: "#f2f2f2",
    justifyContent: "space-between",
    borderRight: true,
    borderRightColor: "black",
  },
  headerCell: {
    borderRight: true,
    borderLeft: true,
    borderColor: "black",
    borderRightColor: "#f2f2f2",
    padding: 6,
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: 8,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: true,
    borderTop: true,
    marginTop: -1,
    borderRight: true,
    borderRightColor: "black",
  },
  tableCell: {
    borderLeft: true,
    borderColor: "black",
    padding: 3,
    fontSize: 9,
    textOverflow: "hidden",
  },
  total: {
    marginLeft: "auto",
    color: "black",
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
  },
  footer: {
    marginTop: 15,
    left: "27%",
  },
  serialPage: {
    backgroundColor: "white",
    paddingHorizontal: 30,
    paddingVertical: 30,
    fontSize: 12,
  },
  serialTitle: {
    fontWeight: 800,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 5,
  },
  serialTable: {
    width: "100%",
  },
  serialRow: {
    flexDirection: "row",
    borderBottom: true,
    padding: 3,
  },
  serialCell1: {
    width: "30%",
  },
  serialCell2: {
    width: "70%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
})

export default PDFGenerator
