import React from 'react'
import * as XLSX from 'xlsx'
import { Button } from 'react-bootstrap'

const inventoryXlsx = (props) => {

    const sales = props.data.map((product)=>{
        return{
           Sku: product.sku, Category: product.category, Stock: product.stock, Quantity: product.stock, Sole_Quantity: product.stockSold, Stock_In_Transit: product.stockInTransit, Price: product.price
        }
    })

    const downloadXLXX=()=>{
        const workSheet=XLSX.utils.json_to_sheet(sales)
        const workBook=XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workBook,workSheet,"Sales")

        XLSX.write(workBook,{bookType:"xlsx",type:"binary"})
        XLSX.writeFile(workBook,"Inventory.xlsx")
    }


  return (
    <div onClick={downloadXLXX}>
     <Button   style={{
                width: "100%",
                height: "48px",
                fontSize: "1.5rem",
               }} >Download Inventory Report XLSX File
     </Button>
    </div>
  )
}

export default inventoryXlsx