import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import { setProductSerialNumbers } from "../../state/actions/invoice"
import styles from "../../styles/components/invoices/serialNumberModal.module.scss"

const SerialNumberModal = ({
  id,
  product,
  setProductSerialNumbers,
  serialNumbersInInvoice,
  mode = "search",
  productSerials,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (searchTerm.length > 14) {
      const matchingSerial = product?.serialNumbers?.filter(
        serialNumber =>
          serialNumber.main?.toLowerCase() === searchTerm.toLowerCase() ||
          serialNumber.complimentary?.toLowerCase() === searchTerm.toLowerCase()
      )

      const matchingPalletSerials = product?.serialNumbers?.filter(
        serialNumber =>
          serialNumber.palletNumber
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
      )

      if (matchingSerial?.length > 0) {
        handlePalletNumberChange(matchingSerial)
      }

      if (matchingPalletSerials?.length > 0) {
        handlePalletNumberChange(matchingPalletSerials)
      }

      setSearchTerm("")
    }
  }, [searchTerm, product?.serialNumbers])

  const handlePalletNumberChange = palletSerials => {
    const newSerialNumbersInInvoice = [...serialNumbersInInvoice]
    palletSerials.forEach(serial => {
      const index = newSerialNumbersInInvoice.findIndex(
        sn => sn.main === serial.main
      )
      if (index === -1) {
        newSerialNumbersInInvoice.push(serial)
      } else {
        newSerialNumbersInInvoice.splice(index, 1)
      }
    })

    setProductSerialNumbers(id, newSerialNumbersInInvoice)
  }

  const filteredSerialNumbers = useMemo(() => {
    return product?.serialNumbers
      ?.filter(serialNumber => {
        const isSerialNumberSold = product.serialNumbersSold?.some(
          soldSerial => soldSerial.main === serialNumber.main
        )

        if (isSerialNumberSold) {
          return false
        }

        return true
      })
      ?.filter(serialNumber => {
        const [palletSerial, mainSerial, complimentarySerial] = [
          serialNumber.main?.toLowerCase(),
          serialNumber.palletNumber?.toLowerCase() || "",
          serialNumber.complimentary?.toLowerCase() || "",
        ]

        const search = searchTerm?.split(";")[0]?.toLowerCase()

        return (
          mainSerial.includes(search) ||
          palletSerial.includes(search) ||
          complimentarySerial.includes(search)
        )
      })
  }, [product?.serialNumbers, product?.serialNumbersSold, searchTerm])

  const serialNumbersToDisplay =
    mode === "search" ? filteredSerialNumbers : productSerials

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        {mode === "search" ? "Add" : "Show Serial Numbers"}
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>
            {mode === "search" ? "Add Serial Numbers" : "Serial Numbers"}
          </Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100 overflow-auto">
          {mode === "search" && (
            <div className={styles.search}>
              <input
                type="text"
                value={searchTerm}
                name="search"
                placeholder="Search"
                className={styles.searchInput}
                onChange={e => {
                  setSearchTerm(e.target.value)
                }}
                autoComplete="off"
              />
              <FaSearch className={styles.searchIcon} />
            </div>
          )}

          {serialNumbersToDisplay?.length ? (
            <div>
              {(mode === "search"
                ? filteredSerialNumbers.sort((a, b) => {
                    if (
                      serialNumbersInInvoice.includes(a) &&
                      !serialNumbersInInvoice.includes(b)
                    ) {
                      return -1
                    } else if (
                      !serialNumbersInInvoice.includes(a) &&
                      serialNumbersInInvoice.includes(b)
                    ) {
                      return 1
                    } else {
                      return 0
                    }
                  })
                : productSerials
              ).map((serialNumber, idx) => (
                <Form.Group key={idx}>
                  {mode === "search" ? (
                    <Form.Check
                      className={styles.serialNumber}
                      type="checkbox"
                      label={`${serialNumber.main} ${
                        serialNumber.complimentary
                          ? serialNumber.complimentary
                          : ""
                      } ${
                        serialNumber.palletNumber
                          ? serialNumber.palletNumber
                          : ""
                      }`}
                      checked={serialNumbersInInvoice.includes(serialNumber)}
                      onChange={() => handlePalletNumberChange([serialNumber])}
                    />
                  ) : (
                    <div className={styles.serialNumber}>
                      {`${serialNumber.main} ${
                        serialNumber.complimentary
                          ? serialNumber.complimentary
                          : ""
                      } ${
                        serialNumber.palletNumber
                          ? serialNumber.palletNumber
                          : ""
                      }`}
                    </div>
                  )}
                </Form.Group>
              ))}
            </div>
          ) : (
            <div>No serial numbers available for this product</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  product: state.invoice.invoiceProducts.find(prod => prod.id === props.id),
  serialNumbersInInvoice: state.invoice.invoiceProducts.find(
    prod => prod.id === props.id
  )?.serialNumbersInInvoice,
})

const mapDispatchToProps = dispatch => ({
  setProductSerialNumbers: (id, serialNumbers) =>
    dispatch(setProductSerialNumbers(id, serialNumbers)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumberModal)
