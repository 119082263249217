import React, { useState } from 'react'
import moment from 'moment'
import RangeCalender from './rangeCalender'
import SalesXlsx from './salesXlsx'
import styles from '../../styles/components/sales/sales.module.scss'
import { getTotal } from '../../state/utils'
const _ = require('lodash')

const MonthlySales = props => {
  const [pricePerDate, setPricePerDate] = useState(0)
  const [costPerDate, setCostPerDate] = useState(0)
  const [profitPerDate, setProfitPerDate] = useState(0)

  let arr = props.arr
  let totalCost = 0
  let totalActualPrice = getTotal(arr)
  let profit = 0

  //calculate total cost profit and total sales
  arr.forEach(product => {
    totalCost = totalCost + product.cost * product.quantity
  })
  profit = totalActualPrice - totalCost

  //group products by month
  const data = arr
  let grouped_items = _.groupBy(data, b =>
    moment(b.InvDate).startOf('month').format('YYYY/MM')
  )

  _.values(grouped_items).forEach(arr =>
    arr.sort((a, b) => moment(a.InvDate).day() - moment(b.InvDate).day())
  )

  return (
    <div className='row'>
      <div className='col-md-9 mt-4'>
        <RangeCalender
          setPricePerDate={setPricePerDate}
          setCostPerDate={setCostPerDate}
          setProfitPerDate={setProfitPerDate}
          data={arr}
        ></RangeCalender>
        {/* <Chart monthlySales={monthlySales} totalSalesPrice={totalSalesPrice}></Chart> */}
      </div>

      <div className='col-md-3 mt-4 '>
        <div>
          <div className=''>
            {/* <CircularProgressBar  totalSalesPrice={totalActualPrice} totalCost={totalCost}></CircularProgressBar> */}
          </div>

          <div className={` ${styles.items} mt-4`}>
            <p className={styles.paddingforchart}>
              Sales By Date Range - Rs {pricePerDate.toLocaleString()}
            </p>
          </div>
          <div className={` ${styles.items} mt-3`}>
            <p className={styles.paddingforchart}>
              Cost By Date Range - Rs {costPerDate.toLocaleString()}
            </p>
          </div>
          <div className={` ${styles.items} mt-3 mb-5`}>
            <p className={styles.paddingforchart}>
              Profit By Date Range - Rs {profitPerDate.toLocaleString()}
            </p>
          </div>
          {/* {
                    arr.length>0 && <SalesPDF data={arr} className="mt-4"></SalesPDF>
                    } */}
          {arr.length > 0 && <SalesXlsx arr={arr}></SalesXlsx>}
          <div className=''>
            <div className={styles.items}>
              <p className={styles.paddingforchart}>
                Total Sales - Rs {totalActualPrice.toLocaleString()}
              </p>
            </div>
            <div className={styles.items}>
              <p className={styles.paddingforchart}>
                Total Cost - Rs {totalCost.toLocaleString()}
              </p>
            </div>
            <div className={` ${styles.items} mb-4`}>
              <p className={styles.paddingforchart}>
                Total Profit - Rs {profit.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlySales
