import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import { FaWindowClose } from "react-icons/fa"
import Grid from "@material-ui/core/Grid"
import { FaPlusCircle } from "react-icons/fa"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import Table from "./productSelect"

// Styles
import modalStyles from "../styles/components/modal.module.scss"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `80vw`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  paper: {
    position: "absolute",
    backgroundColor: "#f3f3f3",
    boxShadow: theme.shadows[5],
    padding: 0,
    outline: "none",
  },
})

class SimpleModal extends React.Component {
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, document } = this.props

    return (
      <div style={{ position: "relative" }}>
        <Button
          className={modalStyles.addProduct}
          onClick={this.handleOpen}
          onKeyDown={this.handleOpen}
          tabIndex={0}
        >
          Add Product
          <span style={{ marginLeft: "8px" }}>
            <FaPlusCircle />
          </span>
        </Button>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div
              role="button"
              tabIndex={0}
              onClick={this.handleClose}
              onKeyDown={this.handleClose}
              style={{
                position: "absolute",
                right: "0",
                margin: "5px",
                cursor: "pointer",
              }}
            >
              <FaWindowClose color="red" />
            </div>
            <Grid container className={modalStyles.wrapper}>
              <Table handleClose={this.handleClose} document={document} />
            </Grid>
          </div>
        </Modal>
      </div>
    )
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(SimpleModal)

const mapStateToProps = state => {
  return {
    products: state.product.products,
  }
}

export default connect(mapStateToProps)(SimpleModalWrapped)
