import React from "react"
import { Button } from "react-bootstrap"
import { navigate } from 'gatsby'
// Styles
import styles from "../styles/utils/backBtn.module.scss"
import { FaArrowCircleLeft } from "react-icons/fa"

export default function backButton({ to, variant }) {
  return (
    <div className={styles.wrapper}>
      <Button
        className={styles.backBtn}
        variant={variant || "light"}
        onClick={() => {
          navigate(to)
        }}
      >
        <FaArrowCircleLeft style={{ marginRight: "6px", color: "grey" }} />
        Go Back
      </Button>
    </div>
  )
}
