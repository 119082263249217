import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { FaChartPie, FaDownload } from "react-icons/fa"
import axios from "axios"
import DatePicker from "react-datepicker"
import moment from "moment"
import InvoiceChart from "../charts/InvoiceChart"
import StockChart from "../charts/stockChart"

const InStockReport = () => {
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async () => {
    setIsLoading(true) // Set isLoading to true
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/stocks`,
        {
          responseType: "blob",
        }
      )

      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  const generateReport = async () => {
    setIsLoading(true) // Set isLoading to true
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/stocks/data`
      )
      setData(res.data)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  return (
    <>
      <h2 className="pb-3 font-weight-bold">Stock Report</h2>

      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={generateReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center  justify-content-center mt-3 mb-2 my-md-0 mr-md-2"
            disabled={isLoading}
          >
            <FaChartPie className="mr-2 " />
            Generate
          </Button>

          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading}
          >
            <FaDownload className="mr-2 " />
            Download
          </Button>
        </div>

        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}

        {/* Invoice chart */}
        {data.length > 0 && !isLoading && (
          <div className="col-md-12">
            <StockChart
              data={data}
              startDate={moment(startDate).format("MMM D")}
              endDate={moment(endDate).format("MMM D")}
            />
          </div>
        )}

        {/* Error */}
        {error && (
          <div className="alert alert-danger mt-5" role="alert">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
  }
}

export default connect(mapStateToProps)(InStockReport)
