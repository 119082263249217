import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import PaymentReceiptDocument from "./paymentReceiptDocument"
import PaymentReceiptPDF from "./paymentReceiptPDF"
import BackButton from "../backButton"

const ViewPaymentReceipt = props => {
  const receiptData = {
    ...props.paymentReceipt,
    customer: props.customers.find(
      customer => customer.code === props.paymentReceipt.customerCode
    ),
    date: moment(new Date(props.paymentReceipt.date)).format("DD-MM-YYYY"),
    chequeDate: moment(new Date(props.paymentReceipt.chequeDate)).format(
      "DD-MM-YYYY"
    ),
    senderAddress: props.senderAddress,
    senderCity: props.senderCity,
    senderTel: props.senderTel,
  }

  return (
    <div className="mt-4">
      <h1>{props.paymentReceipt.paymentReceiptNumber}</h1>
      <BackButton to="/app/payment-receipts" />

      {/* Document */}
      <PaymentReceiptDocument data={receiptData} />

      {/* PDF Download */}
      <PaymentReceiptPDF data={receiptData} />
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    paymentReceipt: state.paymentReceipts.paymentReceipts.find(
      receipt => receipt.paymentReceiptNumber === props.id
    ),
    customers: state.customer.customers,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
  }
}

export default connect(mapStateToProps)(ViewPaymentReceipt)
