import { toast } from "react-toastify"
import axios from "axios"
import _ from "lodash"

// Invoice

//Save invoice to db
export const saveInvoice = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    const invoiceData = {
      date: getState().invoice.date,
      paymentMethod: getState().invoice.paymentMethod,
      salesManager: getState().invoice.salesManager,
      paymentTerms: getState().invoice.paymentTerms,
      dueDate: getState().invoice.dueDate,
      transport:getState().invoice.transport,
      deliverTo: getState().invoice.deliverTo,
      customerCode: getState().invoice.customer.code,
      invoiceProducts: getState().invoice.invoiceProducts.map(product => ({
        sku: product.sku,
        price: product.price,
        quantity: product.quantity,
        discount: product.discount,
        discountType: product.discountType,
        serialNumbers: product.serialNumbersInInvoice,
        newWarranty: product.newWarranty
      })),
      uploader: getState().auth.auth.user._id,
      cancelled: getState().invoice.cancelled,
      quotationNumber: getState().invoice.quotationNumber,
    }
    try {
      const data = await axios.post(
        `${process.env.GATSBY_API_CALL}/invoices`,
        invoiceData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch({
        type: "SAVE_INVOICE",
        data: data.data.invoiceNumber,
      })
      return data.data.invoiceNumber
    } catch (e) {
      console.log(e.response)
      throw new Error(e.response?.data.message)
    }
  }
}

// Set invoice products
export const setProducts = products => {
  return dispatch => {
    dispatch({
      type: "SET_INVOICE_PRODUCTS",
      data: products,
    })
  }
}

// Add product to invoice
export const addProduct = product => {
  return (dispatch, getState) => {
    const invoiceProductsFromState = getState().invoice.invoiceProducts
    const itemExists =
      invoiceProductsFromState &&
      invoiceProductsFromState.find(prod => {
        return prod.id === product
      })

    if (itemExists) {
      toast.error(`Error. Item has already been added.`)
      return null
    }

    const productData = getState().product.products.find(prod => {
      if (prod.id === product) {
        return prod
      } else {
        return null
      }
    })

    if (productData.stock < 1) {
      toast.error("Product is out of stock")
      return
    }

    const newProducts = _.concat(getState().invoice.invoiceProducts || [], {
      ...productData,
      quantity: 1,
      discount: 0,
      discountType: "%",
      newWarranty:"10 years",
      serialNumbersInInvoice: [],
      includeSerialNumbers: false,
    })

    dispatch({
      type: "ADD_PRODUCT",
      data: newProducts,
    })
  }
}

// Delete product from invoice
export const deleteProduct = productId => {
  return (dispatch, getState) => {
    const invoiceProducts = [...getState().invoice.invoiceProducts]

    const newProducts = invoiceProducts.filter(product => {
      if (product.id !== productId) {
        return product
      }
      return null
    })

    dispatch({
      type: "DELETE_PRODUCT",
      data: newProducts,
    })
  }
}

// Set product quantity
export const setProductQuantity = (productId, quantity) => {
  return (dispatch, getState) => {
    const newProducts = [...getState().invoice.invoiceProducts]
    _.forEach(newProducts, product => {
      if (product.id === productId) {
        if (quantity > product.stock) {
          toast.error(`Only ${product.stock} items available in stock.`)
          return
        }

        if (quantity === "") {
          product.quantity = 0
        } else {
          product.quantity = quantity
        }
      }
    })
    dispatch({
      type: "SET_INVOICE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set product discount type
export const setProductDiscountType = (productId, discountType) => {
  return (dispatch, getState) => {
    const newProducts = getState().invoice.invoiceProducts.map(product => {
      if (product.id === productId) {
        return { ...product, discountType }
      } else {
        return product
      }
    })

    dispatch({
      type: `SET_INVOICE_PRODUCTS`,
      data: newProducts,
    })
  }
}


// Set product warranty Time
export const setInvoiceWarrantyTime = (productId, newWarranty) => {
  return (dispatch, getState) => {
    const newProducts = getState().invoice.invoiceProducts.map(product => {
      if (product.id === productId) {
        return { ...product, newWarranty }
      } else {
        return product
      }
    })

    dispatch({
      type: `SET_INVOICE_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Set product discount
export const setProductDiscount = (productId, discount) => {
  return (dispatch, getState) => {
    const newProducts = getState().invoice.invoiceProducts.map(prod => {
      if (prod.id === productId) {
        if (discount) {
          return { ...prod, discount }
        } else {
          return { ...prod, discount: 0 }
        }
      } else {
        return prod
      }
    })
    dispatch({
      type: "SET_INVOICE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set product includeSerialNumbers
export const setProductIncludeSerialNumbers = (
  productId,
  includeSerialNumbers
) => {
  return (dispatch, getState) => {
    const newProducts = [...getState().invoice.invoiceProducts]
    _.forEach(newProducts, product => {
      if (product.id === productId) {
        product.includeSerialNumbers = includeSerialNumbers
        product.serialNumbersInInvoice = []
      }
    })
    dispatch({
      type: "SET_INVOICE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set product serial numbers
export const setProductSerialNumbers = (productId, serialNumbersInInvoice) => {
  return (dispatch, getState) => {
    const newProducts = [...getState().invoice.invoiceProducts]
    _.forEach(newProducts, product => {
      if (product.id === productId) {
        product.serialNumbersInInvoice = serialNumbersInInvoice
      }
    })
    dispatch({
      type: "SET_INVOICE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set invoice customer
export const setCustomer = id => {
  return (dispatch, getState) => {
    const customer = getState().customer.customers.find(customer => {
      return customer._id === id
    })

    dispatch({
      type: "SET_CUSTOMER",
      data: customer,
    })
  }
}

// Clear invoice
export const clearInvoice = () => {
  return dispatch => {
    dispatch({
      type: "CLEAR_INVOICE",
    })
  }
}
