import React from "react"
import _ from "lodash"

import logo from "../../images/logo.png"
import { formatNumber } from "../../state/utils"
import styles from "../../styles/components/paymentReceipts/paymentReceiptDocument.module.scss"

const PaymentReceiptDocument = props => {
  return (
    <div className={styles.paymentReceiptPreview}>
      <div className={styles.companyInfo}>
        <div className={styles.logo}>
          <img src={logo} alt="Gunda Power Logo" />
        </div>
        <div className={styles.senderInfo}>
          <p>{props.data.senderAddress}</p>
          <p>{props.data.senderCity}</p>
          <p>{props.data.senderTel}</p>
          <p>info@gundapower.com / www.gundapower.com</p>
        </div>
      </div>

      {/* Receipt Info */}
      <table className={`mt-5 ${styles.receiptInfo}`}>
        <tbody>
          <tr>
            <td className="pr-4 font-weight-bold">{`Quotation ${
              props.data.quotationNumbers.length > 1 ? "Nos" : "No"
            }`}</td>
            <td>{props.data.quotationNumbers.join(", ")}</td>
          </tr>
          <tr>
            <td className="pr-4 font-weight-bold">Date</td>
            <td>{props.data.date}</td>
          </tr>
          <tr>
            <td className="pr-4 font-weight-bold">Payment Method</td>
            <td>{props.data.paymentMethod}</td>
          </tr>
          <tr>
            <td className="pr-4 font-weight-bold">Amount</td>
            <td>{`LKR ${formatNumber(+props.data.amount)}`}</td>
          </tr>
          <tr>
            <td className="pr-4 font-weight-bold">Received From</td>
            <td>{`${props.data.customer?.name} (${props.data.customer?.code})`}</td>
          </tr>
          {props.data.paymentMethod === "Cash" && (
            <tr>
              <td className="pr-4 font-weight-bold">Remarks</td>
              <td>{props.data.remarks}</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Bank and Cheque Info */}
      {props.data.paymentMethod !== "Cash" && (
        <table className={styles.bankInfo}>
          <tbody>
            {props.data.paymentMethod === "Cheque" && (
              <tr>
                <td className="font-weight-bold">Date </td>
                <td>{props.data.chequeDate}</td>
              </tr>
            )}
            <tr>
              <td className="font-weight-bold">Bank</td>
              <td>{props.data.bank}</td>
            </tr>
            {props.data.paymentMethod === "Cheque" && (
              <tr>
                <td className="font-weight-bold">Cheque No</td>
                <td>{props.data.chequeNumber}</td>
              </tr>
            )}
            {props.data.paymentMethod === "Bank Transfer" && (
              <tr>
                <td className="font-weight-bold">Bank Ref</td>
                <td>{props.data.bankRef}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {props.data.paymentMethod === "Cheque" && (
        <div className={styles.chequeConditions}>
          This Payment Voucher will be valid only after realization of the
          Cheque
        </div>
      )}

      {/* Signatures */}
      <div className={styles.signatures}>
        <div className={styles.signature}>
          <p>Name</p>
          <p>Receiver Signature</p>
        </div>
        <div className={styles.signature}>
          <p>Name</p>
          <p>Customer Signature</p>
        </div>
      </div>
    </div>
  )
}

export default PaymentReceiptDocument
