import React from 'react'
import { PuffLoader } from 'react-spinners'

export default function Loading ({
  height = 'calc(100vh - 135px)',
  text = 'Loading ..'
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginX: 'auto',
        marginY: '2rem',
        height,
      }}
    >
      <PuffLoader size={50} color={'#d3d3d3'} loading={true} />
      <p>{text}</p>
    </div>
  )
}
