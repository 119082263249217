import React, { useState } from "react"
import { connect } from "react-redux"
import { Button, Form } from "react-bootstrap"
import { FaChartPie, FaDownload } from "react-icons/fa"
import axios from "axios"
import DatePicker from "react-datepicker"
import moment from "moment"
import InvoiceChart from "../charts/InvoiceChart"

const categories = [
  "Solar Inverter",
  "PV Modules",
  "Electrical Accessories",
  "Hardware Accessories",
  "Railings & Accessories",
  "DC & AC Cables",
  "Solar Water Pump",
  "Tools & Accessories",
]

const IssuesReport = ({ customers }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [customer, setCustomer] = useState("")
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])

  const downloadReport = async () => {
    setIsLoading(true) // Set isLoading to true
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/issued`,
        {
          params: { startDate, endDate, category: selectedCategories },
          responseType: "blob",
        }
      )

      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  const generateReport = async () => {
    setIsLoading(true) // Set isLoading to true
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/issued/data`,
        {
          params: { startDate, endDate, category: selectedCategories },
        }
      )
      setData(res.data)
    } catch (error) {
      setError(error.response.data.message)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  const handleCategoryChange = category => {
    setSelectedCategories(prevSelectedCategories =>
      prevSelectedCategories.includes(category)
        ? prevSelectedCategories.filter(cat => cat !== category)
        : [...prevSelectedCategories, category]
    )
  }

  const customerOptions = [{ value: "", label: "All Customers" }].concat(
    customers.map(customer => {
      return {
        value: customer._id,
        label: `${customer.code} - ${customer.name}`,
      }
    })
  )

  return (
    <>
      <h2 className="pb-3 font-weight-bold">Issues Report</h2>

      <Form className="d-flex flex-row flex-wrap my-3">
        {categories.map((category, index) => (
          <Form.Group className="d-inline-flex flex-row justify-content-between align-items-center mr-5">
            <div className="" key={index}>
              <input
                type="checkbox"
                onChange={() => handleCategoryChange(category)}
                checked={selectedCategories.includes(category)}
              />
              <label className="ml-2">{category}</label>
            </div>
          </Form.Group>
        ))}
      </Form>
      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column">
          <label>Start date: </label>
          <DatePicker
            selected={startDate}
            onChange={setStartDate}
            className="w-100 px-3 py-2"
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-3 d-flex flex-column">
          <label>End date: </label>
          <DatePicker
            selected={endDate}
            onChange={setEndDate}
            className="w-100 px-3 py-2"
            dateFormat="yyyy-MM-dd"
          />
        </div>

        <div className="col-md-3"></div>
        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={generateReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center  justify-content-center mt-3 mb-2 my-md-0 mr-md-2"
            disabled={isLoading || !startDate || !endDate}
          >
            <FaChartPie className="mr-2 " />
            Generate
          </Button>

          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading || !startDate || !endDate}
          >
            <FaDownload className="mr-2 " />
            Download
          </Button>
        </div>

        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}

        {data.length > 0 && !isLoading && (
          <div className="col-md-12 mt-5 ">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Item</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Discount</th>
                  <th scope="col">Discount Type</th>
                  <th scope="col">Sub Total</th>
                  <th scope="col">Serial Numbers</th>
                  {/* Wifi stick */}
                  <th scope="col">Wifi Serial Number</th>
                </tr>
              </thead>
              <tbody>
                {data.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{moment(item.date).format("MMM D, YYYY")}</td>
                      <td>{item.customerName}</td>
                      {/* Formatted date */}
                      <td>{item.item}</td>
                      <td>{item.price}</td>
                      <td>{item.quantity}</td>
                      <td>{item.discount}</td>
                      <td>{item.discountType}</td>
                      <td>{item.sub_total}</td>
                      {/* if serial number, get serialNumber.main */}
                      <td
                        style={{
                          maxWidth: "200px",
                        }}
                      >
                        {item.serialNumbers.length > 0
                          ? item.serialNumbers.map((serialNumber, idx) => {
                              return serialNumber.main + ", "
                            })
                          : "N/A"}
                      </td>

                      {/* Wifi stick */}
                      <td
                        style={{
                          maxWidth: "200px",
                        }}
                      >
                        {item.serialNumbers.length > 0
                          ? item.serialNumbers.map((serialNumber, idx) => {
                              if (serialNumber.complimentary)
                                return serialNumber.complimentary + ", "
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Error */}
        {error && (
          <div className="alert alert-danger mt-5" role="alert">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
  }
}

export default connect(mapStateToProps)(IssuesReport)
