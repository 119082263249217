import React, { useState } from "react"
import { Button } from "react-bootstrap"
import axios from "axios"
import { FaDownload } from "react-icons/fa"

const ItemsReport = () => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async e => {
    setIsLoading(true) // Set isLoading to true

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/newItemReport`,
        {
          responseType: "blob",
        }
      )

      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      console.log(error)
      setError(error.response)
    } finally {
      setIsLoading(false) // Set isLoading to false when request completes
    }
  }

  return (
    <>
      <h2 className="pb-3 font-weight-bold">Items Report</h2>

      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading}
          >
            <FaDownload className="mr-2 " />
            Download
          </Button>
        </div>

        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}

        {/* Error */}
        {error && (
          <div className="alert alert-danger mt-5" role="alert">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

export default ItemsReport
