import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import PreviewPg from "../previewPg"
import ProductModal from "../productModal"
import ProductTable from "./productTable"
import { Form, Button, Row, Col } from "react-bootstrap"
import Select from "react-select"
import {
  setCreditNoteDate,
  setCreditNoteCustomer,
  setUpdatedCreditNoteProducts,
  setCreditNoteId,
  setCreditNoteInvoice,
  setInvoiceNumber,
} from "../../state/actions/creditNote"
import { getAllInvoices } from "../../state/actions/invoices"
import { setCustomers } from "../../state/actions/customer"

import BackButton from "../backButton"

// Styles
import styles from "../../styles/components/quotations/newQuotation.module.scss"
import { toast } from "react-toastify"

const NewCreditNote = ({
  getCustomers,
  customers,
  date,
  setDate,
  invoiceNumber,
  setInvoiceNumber,
  setSenderAddress,
  setSenderCity,
  setSenderTel,
  customer,
  setCreditNoteCustomer,
  creditNoteProducts,
  invoiceProducts,
  newCreditNote,
  invoices,
  getAllInvoices,
  setCreditNoteInvoice,
  creditNoteInvoice,
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const [filteredInvoices, setFilteredInvoices] = useState([])

  useEffect(() => {
    getAllInvoices()
  }, [getAllInvoices])

  useEffect(() => {
    if (customer && customers.length && Object.keys(customer).length === 0) {
      handleSetCustomer(customers[0]._id)
    } else {
      getCustomers()
    }
  }, [customers.length])

  const customerOptions = customers?.map(customer => {
    return {
      value: customer._id,
      label: `${customer.code} - ${customer.name}`,
    }
  })

  // setCreditNoteCustomer(selectedInvoice.creditNotes.customer)

  const handleSetCustomer = id => {
    const selectedCustomer = customers.find(cust => cust._id === id)
    setCreditNoteCustomer(selectedCustomer)

    // Filter invoices based on the selected customer
    const customerInvoices = invoices.filter(
      inv => inv.customerCode === selectedCustomer.code
    )
    setFilteredInvoices(
      customerInvoices.map(inv => ({
        value: inv._id,
        label: inv.invoiceNumber,
      }))
    )

    setInvoiceNumber(invoiceNumber)
  }

  const handleInputChange = e => {
    e.preventDefault()
    setShowPreview(false)
    switch (e.target.name) {
      case "date":
        setCreditNoteDate(e.target.value)
        break

      case "senderAddress":
        setSenderAddress(e.target.value)
        break
      case "senderCity":
        setSenderCity(e.target.value)
        break
      case "senderTel":
        setSenderTel(e.target.value)
        break
      default:
        return
    }
  }
  return (
    <div className={styles.wrapper}>
      <h1 className="title">New Credit Note</h1>
      {!showPreview && (
        <>
          <BackButton to="/app/creditNotes" />
          <div className={styles.form}>
            <div className={styles.invoice}>
              <h2>Credit Note Details</h2>
              <Form.Group className="form-group">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  size="lg"
                  value={date}
                  name="date"
                  type="date"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>

            <div className={styles.client}>
              <Form.Group>
                <Form.Label>Select Customer</Form.Label>
                <Select
                  isSearchable={true}
                  defaultValue={
                    customerOptions[
                      customerOptions.findIndex(c => c.value === customer?._id)
                    ] || customerOptions[0]
                  }
                  options={customerOptions}
                  onChange={e => handleSetCustomer(e.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Select Invoice Number</Form.Label>
                <Select
                  isSearchable={true}
                  options={filteredInvoices}
                  onChange={e =>
                    setCreditNoteInvoice(
                      invoices.find(inv => inv._id === e.value)
                    )
                  }
                />
              </Form.Group>

              {newCreditNote && newCreditNote.customer && (
                <>
                  <Row style={{ marginTop: "25px" }}>
                    <Col sm="3">
                      <p>Customer Code</p>
                    </Col>
                    <Col sm="9">
                      <p>{newCreditNote.customer.code}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Address</p>
                    </Col>
                    <Col sm="9">
                      <p>{newCreditNote.customer.address}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <p>Telephone</p>
                    </Col>
                    <Col sm="9">
                      <p>{newCreditNote.customer.telephone}</p>
                    </Col>
                  </Row>
                  {newCreditNote.customer.email && (
                    <Row>
                      <Col sm="3">
                        <p>Email</p>
                      </Col>
                      <Col sm="9">
                        <p>{newCreditNote.customer.email}</p>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Product Table & Product Select */}

          <div className={styles.productTable}>
            <h2 className={styles.productTableHeading}>Products</h2>
            <ProductTable document="creditNote" />
            <ProductModal document="creditNote" />
          </div>
        </>
      )}

      {/* Generate Credit Note */}
      {showPreview && <PreviewPg document="creditNote" />}
      <Button
        className={styles.btn}
        variant="light"
        onClick={() => {
          if (
            !showPreview &&
            creditNoteProducts?.some(
              product =>
                product.includeSerialNumbers &&
                product.serialNumbersInCreditNote.length !== product.quantity
            )
          ) {
            toast.error(
              "The serial number count of some products does not match the quantity."
            )
          } else {
            setShowPreview(!showPreview)
          }
        }}
      >
        {!showPreview ? `Generate Preview` : `Back to Edit Credit Note`}
      </Button>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    products: state.product.products,
    customers: state.customer.customers,
    senderAddress: state.invoice.senderAddress,
    senderCity: state.invoice.senderCity,
    senderTel: state.invoice.senderTel,
    date: state.creditNotes?.newCreditNote?.date,
    customer: state.invoice.customer,
    creditNoteCustomer: state.invoice.customer,
    newCreditNote: state.creditNotes?.newCreditNote,
    creditNoteNumber: state.creditNotes?.newCreditNote?.creditNoteNumber,
    creditNoteProducts: state.creditNotes?.newCreditNote?.creditNoteProducts,
    invoices: state.invoices.invoices,
    invoiceNumber: state.creditNotes?.invoice?.invoiceNumber,
    creditNoteInvoice: state.creditNotes?.invoice,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCreditNoteDate: date => dispatch(setCreditNoteDate(date)),
    setInvoiceNumber: data => dispatch(setInvoiceNumber(data)),
    getCustomers: () => dispatch(setCustomers()),
    setCreditNoteCustomer: customer =>
      dispatch(setCreditNoteCustomer(customer)),
    setUpdatedCreditNoteProducts: products =>
      dispatch(setUpdatedCreditNoteProducts(products)),
    setCreditNoteId: id => dispatch(setCreditNoteId(id)),

    getAllInvoices: () => dispatch(getAllInvoices()),
    setCreditNoteInvoice: invoice => dispatch(setCreditNoteInvoice(invoice)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCreditNote)
