import { toast } from "react-toastify"
import axios from "axios"

// Get all payment receipts from db
export const getPaymentReceipts = () => {
  return async (dispatch, getState) => {
    if (
      getState().paymentReceipts.paymentReceipts &&
      getState().paymentReceipts.paymentReceipts.length > 0
    )
      return

    const token = getState().auth.auth.token

    dispatch({
      type: `SET_PAYMENT_RECEIPTS_LOADING`,
      data: true,
    })

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/payment-receipts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: `SET_PAYMENT_RECEIPTS`,
        data: res.data,
      })
      dispatch({
        type: `SET_PAYMENT_RECEIPTS_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(
        `There was an error fetching payment receipts. Please try again later.`
      )
      dispatch({
        type: `SET_PAYMENT_RECEIPTS_LOADING`,
        data: false,
      })
    }
  }
}

// Save new payment receipt to db
export const addPaymentReceipt = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    const paymentReceiptData = {
      ...getState().paymentReceipts.newPaymentReceipt,
      customerCode: getState().paymentReceipts.newPaymentReceipt.customer.code,
    }

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_CALL}/payment-receipt`,
        paymentReceiptData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const newPaymentReceipts = getState().paymentReceipts.paymentReceipts.concat(
        res.data
      )
      dispatch({
        type: `SET_PAYMENT_RECEIPTS`,
        data: newPaymentReceipts,
      })
      return res.data.paymentReceiptNumber
    } catch (e) {
      console.log(e)
      throw new Error(e.response.data.message)
    }
  }
}

// Set Date
export const setDate = date => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_DATE`,
      data: date,
    })
  }
}

// Set Payment Receipt Number
export const setPaymentReceiptNumber = number => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_NUMBER`,
      data: number,
    })
  }
}

// Set Payment Method
export const setPaymentMethod = method => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_PAYMENT_METHOD`,
      data: method,
    })

    dispatch({
      type: `SET_PAYMENT_RECEIPT_CHEQUE_NUMBER`,
      data: "",
    })
    dispatch({
      type: `SET_PAYMENT_RECEIPT_CHEQUE_DATE`,
      data: "",
    })
    dispatch({
      type: `SET_PAYMENT_RECEIPT_BANK_REF`,
      data: "",
    })
    dispatch({
      type: `SET_PAYMENT_RECEIPT_BANK`,
      data: "",
    })
  }
}

// Set  Amount
export const setAmount = amount => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_AMOUNT`,
      data: amount,
    })
  }
}

// Set Bank
export const setBank = bank => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_BANK`,
      data: bank,
    })
  }
}

export const setRemarks = remarks => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_REMARKS`,
      data: remarks,
    })
  }
}

// Set Cheque Number
export const setCheque = number => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_CHEQUE_NUMBER`,
      data: number,
    })
  }
}

// Set Cheque Date
export const setChequeDate = date => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_CHEQUE_DATE`,
      data: date,
    })
  }
}

// Set Bank Ref
export const setBankRef = ref => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_BANK_REF`,
      data: ref,
    })
  }
}

// Set Customer
export const setCustomer = customer => {
  return dispatch => {
    dispatch({
      type: `SET_PAYMENT_RECEIPT_CUSTOMER`,
      data: customer,
    })

    dispatch({
      type: `SET_PAYMENT_RECEIPT_INVOICE_NUMBERS`,
      data: [],
    })
  }
}

// Set Quotation Numbers
export const setQuotationNumbers = quotationNumber => {
  return (dispatch, getState) => {
    const quotationNumbers = getState().paymentReceipts.newPaymentReceipt
      .quotationNumbers

    if (quotationNumbers.includes(quotationNumber)) {
      const newQuotationNumbers = quotationNumbers.filter(
        number => number !== quotationNumber
      )
      dispatch({
        type: "SET_PAYMENT_RECEIPT_QUOTATION_NUMBERS",
        data: newQuotationNumbers,
      })
    } else {
      dispatch({
        type: "SET_PAYMENT_RECEIPT_QUOTATION_NUMBERS",
        data: quotationNumbers.concat(quotationNumber),
      })
    }
  }
}

export const setInvoiceNumbers = invoiceNumber => {
  return (dispatch, getState) => {
    const state = getState()
    const invoiceNumbers =
      state.paymentReceipts.newPaymentReceipt.invoiceNumbers
    const invoices = state.invoices.invoices

    const invoice = invoices.find(inv => inv.invoiceNumber === invoiceNumber)

    if (!invoice) {
      console.error("Invoice not found")
      return
    }

    const quotationNumber = invoice.quotationNumber

    if (invoiceNumbers.includes(invoiceNumber)) {
      const newInvoiceNumbers = invoiceNumbers.filter(
        number => number !== invoiceNumber
      )
      dispatch({
        type: "SET_PAYMENT_RECEIPT_INVOICE_NUMBERS",
        data: newInvoiceNumbers,
      })
      dispatch(setQuotationNumbers(quotationNumber))
    } else {
      dispatch({
        type: "SET_PAYMENT_RECEIPT_INVOICE_NUMBERS",
        data: invoiceNumbers.concat(invoiceNumber),
      })
      dispatch(setQuotationNumbers(quotationNumber))
    }
  }
}

// Clear New Payment Receipt
export const clearNewPaymentReceipt = () => {
  return dispatch => {
    dispatch({
      type: `CLEAR_NEW_PAYMENT_RECEIPT`,
    })
  }
}
