import React, { useMemo } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import _ from "lodash"
import { Button } from "react-bootstrap"



const InventoryPdf = ({ data }) => {
   
  // PDF Document
  const MyDocument = ({ recipient }) => (
    <Document>
        <Page size="A4" style={styles.page}>
        
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell, styles.col1]}>product sku</Text>
            <Text style={[styles.headerCell, styles.col3]}>Category</Text>
            <Text style={[styles.headerCell, styles.col4]}>Serial Numbers</Text>
            <Text style={[styles.headerCell, styles.col5]}>Serial Numbers In Transit</Text>
            <Text style={[styles.headerCell, styles.col6]}>serialNumbersSold</Text>
          </View>
          {
            data && data.map((product,id)=>{
                
                return(
                    <View style={styles.tableRow} wrap={false} key={id}>
                        <Text style={[styles.tableCell, styles.col1]}>{product.sku}</Text>
                       
                        <View style={[styles.tableCell, styles.col3]}>
                              <Text>{product.category}</Text> 
                        </View>
 
                        <View style={[styles.tableCell, styles.col4]}>
                            {
                                product.serialNumbers.map((serial,idx)=>{
                                    
                                    return(
                                        <View key={idx} style={styles.margineTop}>
                                            <View>
                                                <Text>{serial.main }</Text>
                                            </View>
                                            <View>
                                                <Text>{ serial.complimentary && `(WiFi Stick Serial: ${serial.complimentary})`}</Text>
                                            </View>
                                           
                                        </View>
                                    )
                                  
                                })
                            }
                        </View>
                        <View style={[styles.tableCell, styles.col5]}>
                        {
                                product.serialNumbersInTransit.map((serial,idx)=>{
                                    
                                    return(
                                        <View key={idx} style={styles.margineTop}>
                                            <View>
                                                <Text>{serial.main }</Text>
                                            </View>
                                            <View>
                                                <Text>{ serial.complimentary && `(WiFi Stick Serial: ${serial.complimentary})`}</Text>
                                            </View>
                                           
                                        </View>
                                    )
                                  
                                })
                            }
                        </View>
                        <View style={[styles.tableCell, styles.col6]}>
                        {
                                product.serialNumbersSold.map((serial,idx)=>{
                                    
                                    return(
                                        <View key={idx} style={styles.margineTop}>
                                            <View>
                                                <Text>{serial.main }</Text>
                                            </View>
                                            <View>
                                                <Text>{ serial.complimentary && `(WiFi Stick Serial: ${serial.complimentary})`}</Text>
                                            </View>
                                           
                                        </View>
                                    )
                                  
                                })
                            }
                        </View>
                        
                    </View>
                )
            })
          }

        </View>
           
        </Page>
     
    </Document>
  )

  return useMemo(
    () => (
      <>
        <PDFDownloadLink
          document={<MyDocument recipient={"Customer"} />}
          fileName={`serial.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ textTransform: "uppercase", textAlign: "center" }}>
                Loading Download
              </p>
            ) : (
              <p
                style={{
                    marginTop:"10px",
                  textTransform: "uppercase",
                  fontFamily: "Montserrat",
                  color: "#126b91",
                  fontWeight: "bold",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "1.5rem",
                  }}
                >
                  Download Serial No Report PDF
                </Button>
              </p>
            )
          }
        </PDFDownloadLink>
        
      </>
    ),
    []
  )
}

// Stylesheet
const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        marginHorizontal: 30,
        fontSize: 8,
      },
      margineTop:{
        marginTop:10,
        flexDirection: "col"
      },
      header: {
        width: "25%",
        marginTop: 30,
        marginBottom: 30,
      },
      row: {
        flexDirection: "row",
        padding: 2,
        border: true,
        marginTop: -1,
      },
     
      table: {
        marginTop: 25,
        width: "90%",
        borderRight: true,
        borderRightColor: "black",
      },
      col1: {
        width: "20%",
      },
    
      col3: {
        width: "20%",
      },
      col4: {
        width: "20%",
      },
      col5: {
        width: "20%",
      },
      col6: {
        width: "20%",
      },
    
      tableHeader: {
        flexDirection: "row",
        borderTop: true,
        borderBottom: true,
        backgroundColor: "#f2f2f2",
      },
      headerCell: {
        borderRight: true,
        borderLeft: true,
        borderColor: "black",
        borderRightColor: "#f2f2f2",
        padding: 6,
        fontWeight: "bold",
        textTransform: "capitalize",
        fontSize: 8,
        overflow: "hidden",
      },
      tableRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: true,
        borderTop: true,
        marginTop: -1,
      },
      tableCell: {
        borderLeft: true,
        borderColor: "black",
        padding: 6,
        fontSize: 8,
        textOverflow: "hidden",
      },     

})

export default InventoryPdf
