import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Table, Row, Button } from "react-bootstrap"
import { navigate } from "gatsby"
import moment from "moment"
import { getDeliveryNotes } from "../../state/actions/deliveryNotes"
import { setInvoices } from "../../state/actions/invoices"
import { FaTruck, FaArrowLeft, FaArrowRight, FaSearch } from "react-icons/fa"

import styles from "../../styles/components/deliveryNotes/deliveryNotes.module.scss"
import Loading from "../loading"

const DeliveryNotes = ({
  deliveryNotes,
  totalDeliveryNotes,
  totalPages,
  getDeliveryNotes,
  customers,
  setInvoices,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    getDeliveryNotes({
      skip: currentPage,
      limit: itemsPerPage,
      search: searchTerm,
    })
    setInvoices({
      skip: currentPage,
      limit: itemsPerPage,
      search: searchTerm,
    })
  }, [currentPage, itemsPerPage, searchTerm])

  if (deliveryNotes.length === 0 && !searchTerm) return <Loading />

  return (
    <div className={styles.wrapper}>
      <h1 className="title">
        <FaTruck />
        Delivery Notes
      </h1>
      <div className={styles.search}>
        <input
          type="text"
          value={searchTerm}
          name="search"
          placeholder="Search"
          autoComplete="off"
          className={styles.searchInput}
          onChange={e => {
            setSearchTerm(e.target.value)
            setCurrentPage(1)
          }}
        />
        <FaSearch className={styles.searchIcon} />
      </div>
      {deliveryNotes.length ? (
        <>
          {/* Search */}
          <Table hover responsive>
            <thead>
              <tr>
                <th>Delivery Note Number</th>
                <th>Invoice Number</th>
                <th>Customer</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {deliveryNotes
                ?.sort(
                  (a, b) =>
                    Number(b.deliveryNoteNumber?.split("-")[1]) -
                    Number(a.deliveryNoteNumber?.split("-")[1])
                )
                .map((dn, idx) => (
                  <tr
                    key={idx}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/app/delivery-notes/${dn.deliveryNoteNumber}`)
                    }
                  >
                    <td>{dn.deliveryNoteNumber}</td>
                    <td>{dn.invoiceNumber}</td>
                    <td>{`${dn.customerCode} - ${
                      customers.find(
                        customer => customer.code === dn.customerCode
                      )?.name
                    }`}</td>
                    <td>{moment(new Date(dn.date)).format("DD-MM-YYYY")}</td>
                    <td
                      className={
                        dn.status === "Cancelled"
                          ? "text-danger"
                          : dn.status === "Delivered"
                          ? "text-success"
                          : ""
                      }
                    >
                      {dn.status}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Row className="d-flex justify-content-center align-items-center mx-4">
            <div className=" d-flex align-items-center mx-4 my-4 my-lg-0">
              {/* Dropdown to change items per page */}
              <h3
                className="small d-inline mr-2"
                style={{
                  minWidth: "105px",
                }}
              >
                Items per page:
              </h3>
              <select
                className="form-control form-control-lg"
                value={itemsPerPage}
                onChange={e => {
                  setCurrentPage(1)
                  setItemsPerPage(e.target.value)
                }}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <Button
              size="lg"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              variant={currentPage === 1 ? "secondary" : "primary"}
            >
              <FaArrowLeft />
            </Button>

            <span className="mx-3 small">
              Page {currentPage} /{" "}
              {
                // Total pages
                totalPages
              }
            </span>

            <Button
              size="lg"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage >= totalDeliveryNotes / itemsPerPage}
            >
              <FaArrowRight />
            </Button>
          </Row>
        </>
      ) : (
        <p className="text-muted">No Delivery Notes</p>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    deliveryNotes: state.deliveryNotes.deliveryNotes,
    totalDeliveryNotes: state.deliveryNotes.totalDeliveryNotes,
    totalPages: state.deliveryNotes.totalPages,
    customers: state.customer.customers,
    isLoading: state.deliveryNotes.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDeliveryNotes: data => dispatch(getDeliveryNotes(data)),
    setInvoices: data => dispatch(setInvoices(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryNotes)
