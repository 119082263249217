import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Modal, Button, Form } from "react-bootstrap"
import { FaWindowClose, FaSearch } from "react-icons/fa"
import { setProductSerialNumbers } from "../../state/actions/invoice"
import { setCreditNoteProductSerialNumbers } from "../../state/actions/creditNote"
import { useMemo } from "react"
import styles from "../../styles/components/invoices/serialNumberModal.module.scss"

const SerialNumberModalForCreditNote = ({
  id,
  documentType,
  product,
  setProductSerialNumbers,
  setCreditNoteProductSerialNumbers,
  serialNumbersInInvoice,
  serialNumbersInCreditNote,
  invoice,
}) => {
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (searchTerm.length > 15) {
      const matchingSerial = product?.serialNumbers.filter(
        serialNumber =>
          serialNumber.main?.toLowerCase() === searchTerm.toLowerCase() ||
          serialNumber.complimentary?.toLowerCase() ===
            searchTerm.toLowerCase() ||
          serialNumber.palletNumber?.toLowerCase() === searchTerm.toLowerCase()
      )
      if (matchingSerial) {
        handleSerialNumberChange(matchingSerial)
      }

      setSearchTerm("")
    }
  }, [searchTerm, product?.serialNumbers])

  const handleSerialNumberChange = serial => {
    const action =
      documentType === "creditNote"
        ? setCreditNoteProductSerialNumbers
        : setProductSerialNumbers
    const serialNumbers =
      documentType === "creditNote"
        ? product?.serialNumbersInCreditNote
        : serialNumbersInInvoice
    if (serialNumbers.includes(serial)) {
      action(
        id,
        serialNumbers.filter(sn => sn !== serial)
      )
    } else {
      action(id, [...serialNumbers, serial])
    }
  }

  const filteredSerialNumbers = useMemo(() => {
    if (documentType === "invoice") {
      return product.serialNumbers
        ?.filter(serialNumber => {
          // Memoized check for sold serial numbers
          const isSerialNumberSold = product.serialNumbersSold?.some(
            soldSerial => soldSerial.main === serialNumber.main
          )

          // Early return for sold serial numbers
          if (isSerialNumberSold) {
            return false
          }

          return true
        })
        ?.filter(serialNumber => {
          // Extract mainSerial and complimentarySerial using array destructuring
          const [mainSerial, complimentarySerial, palletSerial] = [
            serialNumber.main?.toLowerCase(),
            serialNumber.complimentary?.toLowerCase() || "",
            serialNumber.palletNumber?.toLowerCase() || "",
          ]

          const search = searchTerm?.split(";")[0]?.toLowerCase()

          // Combine conditions for search filtering in a single return statement
          return (
            mainSerial?.includes(search) ||
            complimentarySerial?.includes(search) ||
            palletSerial?.includes(search)
          )
        })
    } else {
      return product?.serialNumbersSold?.filter(serialNumber => {
        // Extract mainSerial and complimentarySerial using array destructuring
        const [mainSerial, complimentarySerial, palletSerial] = [
          serialNumber.main?.toLowerCase(),
          serialNumber.complimentary?.toLowerCase() || "",
          serialNumber.palletNumber?.toLowerCase() || "",
        ]

        const search = searchTerm?.split(";")[0]?.toLowerCase()

        // Combine conditions for search filtering in a single return statement
        return (
          mainSerial?.includes(search) ||
          complimentarySerial?.includes(search) ||
          palletSerial?.includes(search)
        )
      })
    }
  }, [
    product?.serialNumbers,
    product?.serialNumbersSold,
    searchTerm,
    invoice,
    id,
  ])

  return (
    <>
      <Button onClick={() => setShow(true)} variant="secondary">
        Add
      </Button>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <Modal.Title>Add Serial Numbers</Modal.Title>
          <Button
            tabIndex={2}
            variant="danger"
            onClick={() => setShow(false)}
            onKeyDown={() => setShow(false)}
          >
            <FaWindowClose />
          </Button>
        </Modal.Header>
        <Modal.Body className="h-100">
          {/* Search */}
          <div className={styles.search}>
            <input
              type="text"
              value={searchTerm}
              name="search"
              placeholder="Search"
              className={styles.searchInput}
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              autoComplete="off"
            />
            <FaSearch className={styles.searchIcon} />
          </div>

          {/* Serial Numbers Handling */}
          {product &&
          (documentType === "invoice" || documentType === "creditNote") ? (
            <div>
              {/* Determine if there are relevant serial numbers based on document type */}
              {(documentType === "invoice" &&
                product.serialNumbers &&
                product.serialNumbers.length > 0) ||
              (documentType === "creditNote" &&
                product.serialNumbersSold &&
                product.serialNumbersSold.length > 0) ? (
                filteredSerialNumbers
                  .sort((a, b) => {
                    const serialNumbersList =
                      documentType === "creditNote"
                        ? serialNumbersInCreditNote
                        : serialNumbersInInvoice

                    if (
                      serialNumbersList.includes(a) &&
                      !serialNumbersList.includes(b)
                    ) {
                      return -1
                    } else if (
                      !serialNumbersList.includes(a) &&
                      serialNumbersList.includes(b)
                    ) {
                      return 1
                    } else {
                      return 0
                    }
                  })
                  .map((serialNumber, idx) => (
                    <Form.Group key={idx}>
                      <Form.Check
                        className={styles.serialNumber}
                        type="checkbox"
                        label={`${serialNumber.main} ${
                          serialNumber.complimentary
                            ? ` (${serialNumber.complimentary})`
                            : ""
                        } ${
                          serialNumber.palletNumber
                            ? ` ${serialNumber.palletNumber}`
                            : ""
                        }`}
                        checked={
                          documentType === "creditNote"
                            ? serialNumbersInCreditNote.includes(serialNumber)
                            : serialNumbersInInvoice.includes(serialNumber)
                        }
                        onChange={() =>
                          handleSerialNumberChange(serialNumber, documentType)
                        }
                      />
                    </Form.Group>
                  ))
              ) : (
                <div>
                  No serial numbers{" "}
                  {documentType === "creditNote" ? "sold" : ""} available for
                  this product
                </div>
              )}
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => {
  const { id, documentType } = props
  let product
  if (documentType === "invoice") {
    product = state.invoice.invoiceProducts.find(prod => prod.id === id)
  } else if (documentType === "creditNote") {
    product = state.creditNotes.newCreditNote.creditNoteProducts.find(
      prod => prod.id === id
    )
  }
  return {
    product,
    serialNumbersInInvoice: product?.serialNumbersInInvoice,
    serialNumbersInCreditNote: product?.serialNumbersInCreditNote,
  }
}

const mapDispatchToProps = dispatch => ({
  setProductSerialNumbers: (id, serialNumbers) =>
    dispatch(setProductSerialNumbers(id, serialNumbers)),
  setCreditNoteProductSerialNumbers: (id, serialNumbers) =>
    dispatch(setCreditNoteProductSerialNumbers(id, serialNumbers)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SerialNumberModalForCreditNote)
