import React from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { setProducts } from "../state/actions/product"
import { setCustomers } from "../state/actions/customer"
import Layout from "../components/layout"

const PrivateRoute = ({
  setProducts,
  setCustomers,
  component: Component,
  location,
  isLoggedIn,
  ...rest
}) => {
  if (!isLoggedIn && location.pathname !== `/`) {
    navigate("/app/login")
    return null
  } else {
    setProducts()
    setCustomers()
  }
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setProducts: () => dispatch(setProducts()),
    setCustomers: () => dispatch(setCustomers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
