import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { FaDownload } from "react-icons/fa"
import axios from "axios"
import moment from "moment"

const DebtorsReport = ({ customers }) => {
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/debtors`,
        {
          responseType: "blob",
        }
      )
      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      console.log(error.response)
      setError(error.response)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <h2 className="pb-3 font-weight-bold">Debtors Report</h2>
      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading}
          >
            <FaDownload className="mr-2 " />
            Download Report
          </Button>
        </div>
        {/* Loading spinner and error handling */}
        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
            <p>Loading... This may take a while..</p>
          </div>
        )}

        {data.length > 0 && (
          <div className="col-md-12 mt-5">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Opening Bal</th>
                  <th>Address 1</th>
                  <th>Address 2</th>
                  <th>Address 3</th>
                  <th>Telephone</th>
                  <th>Fax</th>
                  <th>Mobile No</th>
                  <th>Email</th>
                  <th>Contact Person</th>
                  <th>Customer VAT No</th>
                  <th>Credit Days</th>
                  <th>Credit Period</th>
                  <th>Salesman</th>
                </tr>
              </thead>
              <tbody>
                {data.map(customer => (
                  <tr key={customer.code}>
                    <td>{customer.name}</td>
                    <td>{customer.openingBalance}</td>
                    <td>{customer.address1}</td>
                    <td>{customer.address2}</td>
                    <td>{customer.address3}</td>
                    <td>{customer.telephone}</td>
                    <td>{customer.fax}</td>
                    <td>{customer.mobileNo}</td>
                    <td>{customer.email}</td>
                    <td>{customer.contactPerson}</td>
                    <td>{customer.vatNo}</td>
                    <td>{customer.creditDays}</td>
                    <td>{customer.creditPeriod}</td>
                    <td>{customer.salesman}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
  }
}

export default connect(mapStateToProps)(DebtorsReport)
