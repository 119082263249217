import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, Card } from "react-bootstrap"
import { BsCheckCircle } from "react-icons/bs"
import { toast } from "react-toastify"
import {
  addPaymentReceipt,
  setPaymentReceiptNumber,
} from "../../state/actions/paymentReceipts"
import PaymentReceiptDocument from "./paymentReceiptDocument"
import PaymentReceiptPDF from "./paymentReceiptPDF"
import { formatNumber } from "../../state/utils"

const PreviewPaymentReceipt = props => {
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const saveBtnHandler = async () => {
    setIsLoading(true)
    try {
      const paymentReceiptNumber = await props.addPaymentReceipt()
      props.setPaymentReceiptNumber(paymentReceiptNumber)
      toast.success("Success: Payment Receipt Saved!")
      setIsSaved(true)
    } catch (e) {
      toast.error(`There was an error saving the document. ${e}`)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <>
      {isSaved ? (
        <div>
          <Card className="text-center" style={{ marginBottom: "10px" }}>
            <Card.Body>
              <BsCheckCircle style={{ fontSize: "4rem" }} />
              <h1>
                <span style={{ fontWeight: "bold" }}>
                  Payment Receipt Saved
                </span>
              </h1>
              <h4>
                <span style={{ fontWeight: "bold" }}>
                  Payment Receipt Number:{" "}
                </span>
                {props.paymentReceipt.paymentReceiptNumber}
              </h4>
              <h4>
                <span style={{ fontWeight: "bold" }}>Customer Name: </span>
                {props.paymentReceipt.customer.name}
              </h4>
              <h4>
                <span style={{ fontWeight: "bold" }}>Amount: </span>
                {formatNumber(+props.paymentReceipt.amount)}
              </h4>
            </Card.Body>
          </Card>
          <PaymentReceiptPDF data={props.paymentReceipt} />
        </div>
      ) : (
        <>
          <PaymentReceiptDocument data={props.paymentReceipt} />
          <Button
            variant="primary"
            className="w-100 my-4 text-uppercase font-weight-bold p-4"
            onClick={() => saveBtnHandler()}
            disabled={isLoading}
          >
            Save Payment Receipt
          </Button>
        </>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    paymentReceipt: state.paymentReceipts.newPaymentReceipt,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addPaymentReceipt: () => dispatch(addPaymentReceipt()),
    setPaymentReceiptNumber: paymentReceiptNumber =>
      dispatch(setPaymentReceiptNumber(paymentReceiptNumber)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewPaymentReceipt)
