import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { connect } from "react-redux"
import { login, autoLogin } from "../state/actions/auth"

import styles from "../styles/components/login.module.scss"
import { Col, Row, Container, Form, Button } from "react-bootstrap"
import logo from "../images/logo.png"
import { ToastContainer } from "react-toastify"

const Login = ({ login, isLoggedIn, autoLogin }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/app/products/`)
    }
  }, [isLoggedIn])

  useEffect(() => {
    autoLogin()
  }, [])

  const handleLogin = e => {
    e.preventDefault()
    login({
      email,
      password,
    })
  }

  return (
    <div className={styles.container}>
      <ToastContainer />

      <div className={styles.background} />
      <Container className={styles.form}>
        <Col lg={10} className={styles.login}>
          {/* Logo */}
          <div className={styles.logo}>
            <img src={logo} alt="Gunda Power" />
            <span>/</span>
            <h1>System Login</h1>
          </div>

          <div className={styles.text}>
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="lg"
                    placeholder="email@gundapower.com"
                    required
                    value={email}
                    type="email"
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Password
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    size="lg"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => {
                      setPassword(e.target.value)
                    }}
                  />
                </Col>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                size="lg"
                className={styles.btn}
                onClick={handleLogin}
                disabled={!email || !password}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: user => dispatch(login(user)),
    autoLogin: () => dispatch(autoLogin()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
