import { toast } from "react-toastify"
import axios from "axios"
import _ from "lodash"

import { navigate } from "gatsby-link"

// Get all credit notes from db without pagination and search
export const getCreditNotes = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: `SET_CREDIT_NOTE_LOADING`,
      data: true,
    })

    // Fetch the current state
    const state = getState()

    if (
      state.creditNotes.creditNotes &&
      state.creditNotes.creditNotes.length > 0
    ) {
      dispatch({
        type: `SET_CREDIT_NOTE_LOADING`,
        data: false,
      })
      return
    }

    const token = state.auth.auth.token

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/credit-notes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: `SET_CREDIT_NOTES`,
        data: res.data.creditNotes,
      })

      dispatch({
        type: `SET_TOTAL_CREDIT_NOTES`,
        data: res.data.totalCreditNotes,
      })

      dispatch({
        type: `SET_CREDIT_NOTE_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data?.error || e.message}`)
      dispatch({
        type: `SET_CREDIT_NOTE_LOADING`,
        data: false,
      })
    }
  }
}

// Get all credit notes from db
// export const getCreditNotes = data => {
//   return async (dispatch, getState) => {
//     const skip = data?.skip || 1
//     const limit = data?.limit || 25
//     const search = data?.search || "" // Add search term

//     dispatch({
//       type: `SET_CREDIT_NOTE_LOADING`,
//       data: true,
//     })

//     if (
//       getState().creditNotes.creditNotes &&
//       getState().creditNotes.creditNotes.length > 0
//     ) {
//       dispatch({
//         type: `SET_CREDIT_NOTE_LOADING`,
//         data: false,
//       })
//       return
//     }

//     const token = getState().auth.auth.token

//     try {
//       let searchParam = search

//       // Check if search term is not numeric
//       if (search !== "" && !/^\d+$/.test(search)) {
//         // Assume it's a customer name and try to find the customer codes
//         const customers = getState().customer.customers // Access customers from Redux state

//         // Find matching customers based on a case-insensitive substring match
//         const matchingCustomers = customers.filter(customer =>
//           customer.name.toLowerCase().includes(search.toLowerCase())
//         )

//         // Take the top result as the search parameter
//         const topCustomer = matchingCustomers[0]

//         // Use the customer code of the top result as the search term
//         if (topCustomer) {
//           searchParam = topCustomer.code
//         }
//       }

//       const res = await axios.get(
//         `${
//           process.env.GATSBY_API_CALL
//         }/credit-notes?page=${skip}&limit=${limit}${
//           searchParam && "&search=" + searchParam
//         }`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )

//       dispatch({
//         type: `SET_CREDIT_NOTES`,
//         data: res.data.creditNotes,
//       })

//       dispatch({
//         type: `SET_TOTAL_CREDIT_NOTES`,
//         data: res.data.totalCreditNotes,
//       })

//       dispatch({
//         type: `SET_TOTAL_PAGES`,
//         data: res.data.totalPages,
//       })

//       dispatch({
//         type: `SET_CREDIT_NOTE_LOADING`,
//         data: false,
//       })
//     } catch (e) {
//       console.log(e)
//       toast.error(`Error: ${e.response?.data.error}`)
//       dispatch({
//         type: `SET_CREDIT_NOTE_LOADING`,
//         data: false,
//       })
//     }
//   }
// }

// Save new credit note to db
export const saveCreditNote = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    const state = getState()

    const newCreditNote = state.creditNotes.newCreditNote

    const creditNoteData = {
      date: newCreditNote.date,
      creditNoteNumber: newCreditNote.creditNoteNumber,
      customerCode: newCreditNote.customer.code,
      creditNoteCustomer: newCreditNote.customer,
      invoice: [],
      invoiceNumber: state.creditNotes.invoice.invoiceNumber,
      creditNoteProducts: newCreditNote.updatedCreditNoteProducts.map(
        product => ({
          sku: product.sku,
          price: product.price,
          quantity: product.quantity,
          serialNumbers: product.serialNumbersInCreditNote.map(sn => ({
            main: sn.main,
            complimentary: sn.complimentary,
            palletNumber: sn.palletNumber,
          })),
        })
      ),

      uploader: state.auth.auth.user._id,
    }

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_CALL}/credit-notes`,
        creditNoteData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      // const newCreditNote = getState().creditNote.creditNotes.concat(res.data)

      dispatch({
        type: `SET_CREDIT_NOTES`,
        data: res.data,
      })
      toast.success("Success: Credit Note added!")
      navigate("/app/credit-notes")
      return res.data.creditNoteNumber
    } catch (e) {
      const errorMessage =
        e.response?.data?.message || "Failed to save the credit note."
      toast.error(`Error: ${errorMessage}`)
      console.error("Save Credit Note Error:", errorMessage)
      throw new Error(errorMessage)
    }
  }
}

// Cancel credit note

export const cancelCreditNote = (id, products) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/credit-notes/${id}`,
        { cancelled: true, products },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch(creditNoteCancelled(id))

      toast.success("Success: Credit Note cancelled!")
      return true
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}


// Delete credit note
export const deleteCreditNote = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/credit-notes/delete/${id}`,
        { deleted: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newCreditNote = getState().creditNote.creditNotes.map(
        creditNote => {
          if (creditNote._id === id) {
            creditNote.deleted = true
          }
          return creditNote
        }
      )

      dispatch({
        type: `SET_CREDIT_NOTES`,
        data: newCreditNote,
      })

      toast.success("Success: Credit Note deleted!")
    } catch (e) {
      console.log(e)
      toast.error(`Error: ${e.response?.data.message} `)
    }
  }
}

// Set credit note products
export const setCreditNoteProducts = products => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_PRODUCTS`,
      data: products,
    })
  }
}

export const setUpdatedCreditNoteProducts = products => {
  return dispatch => {
    dispatch({
      type: `SET_UPDATED_CREDIT_NOTE_PRODUCTS`,
      data: products,
    })
  }
}

// Add product to credit note
export const addCreditNoteProduct = product => {
  return (dispatch, getState) => {
    const creditNoteProductsFromState = getState().creditNotes.newCreditNote
      .creditNoteProducts

    const itemExists =
      creditNoteProductsFromState &&
      creditNoteProductsFromState?.find(prod => {
        return prod.id === product
      })

    if (itemExists) {
      toast.error(`Error. Item has already been added.`)
      return
    }

    const productData = getState().product.products.find(
      prod => prod.id === product
    )

    const newProduct = [
      {
        ...productData,
        quantity: 1,
        serialNumbersInCreditNote: [],
        includeSerialNumbers: false,
      },
    ]

    const newProducts = _.concat(creditNoteProductsFromState || [], newProduct)

    dispatch({
      type: `ADD_CREDIT_NOTE_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Delete product from credit note
export const deleteCreditNoteProduct = productId => {
  return (dispatch, getState) => {
    const newProducts = getState().creditNotes.newCreditNote.creditNoteProducts.filter(
      prod => prod.id !== productId
    )

    dispatch({
      type: `SET_CREDIT_NOTE_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Update product quantity in credit note
export const setCreditNoteProductQuantity = (productId, quantity) => {
  return (dispatch, getState) => {
    const newProducts = getState().creditNotes.newCreditNote.creditNoteProducts.map(
      prod => {
        if (prod.id === productId) {
          return { ...prod, quantity }
        } else {
          return prod
        }
      }
    )

    dispatch({
      type: `SET_CREDIT_NOTE_PRODUCTS`,
      data: newProducts,
    })
  }
}

// Set product includeSerialNumbers
export const setCreditNoteProductIncludeSerialNumbers = (
  productId,
  includeSerialNumbers
) => {
  return (dispatch, getState) => {
    const newProducts = getState().creditNotes.newCreditNote.creditNoteProducts.map(
      product => {
        if (product.id === productId) {
          return {
            ...product,
            includeSerialNumbers: includeSerialNumbers,
            serialNumbersInCreditNote: includeSerialNumbers
              ? product.serialNumbersInCreditNote
              : [], // Clear serial numbers only if we are disabling them
          }
        }
        return product
      }
    )

    dispatch({
      type: "SET_CREDIT_NOTE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set product serial numbers
export const setCreditNoteProductSerialNumbers = (
  productId,
  serialNumbersInCreditNote
) => {
  return (dispatch, getState) => {
    const newProducts = [
      ...getState().creditNotes.newCreditNote.creditNoteProducts,
    ]
    _.forEach(newProducts, product => {
      if (product.id === productId) {
        product.serialNumbersInCreditNote = serialNumbersInCreditNote
      }
    })
    dispatch({
      type: "SET_CREDIT_NOTE_PRODUCTS",
      data: newProducts,
    })
  }
}

// Set Date
export const setCreditNoteDate = date => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_DATE`,
      data: date,
    })
  }
}

// Set Credit Note Number
export const setCreditNoteNumber = number => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_NUMBER`,
      data: number,
    })
  }
}

// Set Credit Note ID
export const setCreditNoteId = id => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_ID`,
      data: id,
    })
  }
}

// Set Invoice
export const setCreditNoteInvoice = inv => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_INVOICE`,
      data: inv,
    })
  }
}

export const setInvoiceNumber = number => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_INVOICE_NUMBER`,
      data: number,
    })
  }
}

// Set Customer
export const setCreditNoteCustomer = customer => {
  return dispatch => {
    dispatch({
      type: `SET_CREDIT_NOTE_CUSTOMER`,
      data: customer,
    })
  }
}

// Clear new credit note fields
export const clearNewCreditNote = () => {
  return dispatch => {
    dispatch({
      type: `CLEAR_NEW_CREDIT_NOTE`,
    })
  }
}

const creditNoteCancelled = id => {
  return dispatch => {
    dispatch({
      type: "CANCEL_CREDIT_NOTE",
      data: id,
    })
  }
}
