import React, { useMemo } from "react"
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer"
import _ from "lodash"
import { Button } from "react-bootstrap"
import { formatNumber } from "../../state/utils"
import logo from "../../images/logo.png"

const DeliveryNotePdf = ({ data }) => {
  // PDF Document
  const MyDocument = ({ recipient }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            {/* Logo */}
            <View style={styles.logo}>
              <Image src={logo} />
            </View>

            {/* Sender Information */}
            <View style={styles.sender}>
              <Text>{data.senderAddress}</Text>
              <Text>{data.senderCity}</Text>
              <Text>{data.senderTel}</Text>
              <Text>info@gundapower.com / www.gundapower.com</Text>
            </View>
          </View>

          {/* Deliver Note Information */}
          <View style={{ marginLeft: "auto", minWidth: 225 }}>
            <Text style={{ fontWeight: 800, marginBottom: 5, fontSize: 13 }}>
              Delivery Note - {recipient} Copy
            </Text>
            <View style={styles.row}>
              <Text style={styles.cell1}>Order Date</Text>
              <Text style={styles.cell2}>{data.date}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>Invoice No.</Text>
              <Text style={styles.cell2}>{data.invoiceNumber}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>Delivery Note No.</Text>
              <Text style={styles.cell2}>{data.deliveryNoteNumber}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>Customer Code</Text>
              <Text style={styles.cell2}>{data.customer?.code}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>Dispatch Date</Text>
              <Text style={styles.cell2}>{data.dispatchDate}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.cell1}>Delivery Method</Text>
              <Text style={styles.cell2}>{data.deliveryMethod}</Text>
            </View>
          </View>
        </View>

        <View style={styles.fixedHeader} fixed></View>

        {/* Billing and Delivery Information */}
        <View style={styles.billingAndDelivery}>
          {/* Billing Information */}
          <View>
            <Text style={{ fontSize: 9, marginTop: -15, marginBottom: 5 }}>
              Bill To:{" "}
            </Text>
            <View style={styles.customerInfo}>
              <Text>{data.customer?.name}</Text>
              <Text>{data.customer?.address}</Text>
              <Text>{data.customer?.telephone}</Text>
              <Text>{data.customer?.email}</Text>
            </View>
          </View>

          {/* Delivery Information */}
          <View style={styles.deliveryInfo}>
            <Text style={{ fontSize: 9, marginTop: -15, marginBottom: 5 }}>
              Deliver To:{" "}
            </Text>
            <View style={styles.customerInfo}>
              <Text style={{ fontSize: 8, marginBottom: 2 }}>[Name]</Text>
              <Text style={{ fontSize: 8, marginBottom: 2 }}>
                [Company Name]
              </Text>
              <Text style={{ fontSize: 8 }}>[Street Address]</Text>
            </View>
          </View>
        </View>

        {/* Delivery Note Items */}
        <View style={styles.table}>
          <Text
            style={{
              borderTop: true,
              borderLeft: true,
              borderRight: true,
              marginLeft: "auto",
              width: "50%",
              textAlign: "center",
              padding: 2,
            }}
          >
            Customer to Complete
          </Text>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell, styles.col1]}>#</Text>
            <Text style={[styles.headerCell, styles.col2]}>
              Item Description
            </Text>
            <Text style={[styles.headerCell, styles.col3]}>Ordered</Text>
            <Text style={[styles.headerCell, styles.col4]}>Received</Text>
            <Text
              style={[styles.headerCell, styles.col5, { borderRight: false }]}
            >
              Remarks
            </Text>
          </View>

          {/* Table Body */}
          {data.products.map((product, idx) => (
            <View style={styles.tableRow} wrap={false} key={idx}>
              <Text style={[styles.tableCell, styles.col1]}>{idx + 1}</Text>

              <View style={[styles.tableCell, styles.col2]}>
                <Text>{product.productName}</Text>
                {product.productDescription !== "N/A" && (
                  <Text>{product.productDescription}</Text>
                )}
                <Text>{product.sku}</Text>
              </View>

              <Text style={[styles.tableCell, styles.col3]}>
                {product.quantity}
              </Text>
              <Text style={[styles.tableCell, styles.col4]}></Text>
              <Text style={[styles.tableCell, styles.col5]}></Text>
            </View>
          ))}
        </View>

        {/* Signature Tables */}
        <View style={styles.signatures}>
          {/* Issuer Signature Table */}
          <View style={styles.signatureTable}>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Issuer Name</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>NIC No.</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Date</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Signature</Text>
            </View>
          </View>

          {/* Receiver Signature Table */}
          <View style={styles.signatureTable}>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Receiver's Name</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>NIC No.</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Date</Text>
            </View>
            <View style={styles.signatureRow}>
              <Text style={styles.signatureCell1}>Signature</Text>
            </View>
          </View>
        </View>

        {/* Terms and Conditions */}
        <View style={styles.terms}>
          <Text style={{ textAlign: "center", fontSize: 10, marginBottom: 2 }}>
            Terms &amp; Conditions
          </Text>
          <Text style={{ fontSize: 9 }}>
            Upon receiving the goods, any product damages or shortages should be
            reported immediately to Gunda Power (Pvt) Ltd.
          </Text>
        </View>

        {data.products.some(
          product => product.serialNumbersInInvoice?.length !== 0
        ) && (
          <>
            <Text style={styles.serialTitle}>
              Serial Numbers ({data.deliveryNoteNumber})
            </Text>
            <View style={styles.serialTable}>
              <View style={styles.serialRow}>
                <Text style={styles.serialCell1}>Item</Text>
                <Text style={styles.serialCell2}>Serial Numbers</Text>
              </View>
              {data.products.map((product, idx) => {
                if (
                  !product.serialNumbersInInvoice ||
                  product.serialNumbersInInvoice.length === 0
                )
                  return

                return (
                  <View key={idx} style={styles.serialRow}>
                    <View style={styles.serialCell1}>
                      <Text>{product.productName}</Text>
                      {product.productDescription !== "N/A" && (
                        <Text>{product.productDescription}</Text>
                      )}
                      <Text>{product.sku}</Text>
                    </View>
                    <View style={styles.serialCell2}>
                      {product.serialNumbersInInvoice.map((serial, idx) => (
                        <Text
                          key={idx}
                          style={{
                            backgroundColor: "#eee",
                            marginRight: 7,
                            marginBottom: 3,
                            fontSize: 10,
                          }}
                        >
                          {serial.main}{" "}
                          {product.category === "Solar Inverter" &&
                            serial.complimentary &&
                            `(WiFi Stick Serial: ${serial.complimentary})`}
                        </Text>
                      ))}
                    </View>
                  </View>
                )
              })}
            </View>
          </>
        )}

        {/* Page Number */}
        <Text
          style={styles.pageNumber}
          fixed
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  )

  return useMemo(
    () => (
      <>
        <PDFDownloadLink
          document={<MyDocument recipient={"Warehouse"} />}
          fileName={`${data.deliveryNoteNumber} ${data.customer?.name} (Warehouse Copy).pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ textTransform: "uppercase", textAlign: "center" }}>
                Loading Download
              </p>
            ) : (
              <p
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Montserrat",
                  color: "#126b91",
                  fontWeight: "bold",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "1.5rem",
                  }}
                >
                  Download Delivery Note - Warehouse Copy
                </Button>
              </p>
            )
          }
        </PDFDownloadLink>
        <PDFDownloadLink
          document={<MyDocument recipient={"Customer"} />}
          fileName={`${data.deliveryNoteNumber} ${data.customer?.name} (Customer Copy).pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <p style={{ textTransform: "uppercase", textAlign: "center" }}>
                Loading Download
              </p>
            ) : (
              <p
                style={{
                  textTransform: "uppercase",
                  fontFamily: "Montserrat",
                  color: "#126b91",
                  fontWeight: "bold",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    height: "48px",
                    fontSize: "1.5rem",
                  }}
                >
                  Download Delivery Note - Customer Copy
                </Button>
              </p>
            )
          }
        </PDFDownloadLink>
      </>
    ),
    []
  )
}

// Stylesheet
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    paddingHorizontal: 30,
    fontSize: 12,
    width: "100%",
  },
  header: {
    flexDirection: "row",
    marginTop: 30,
  },
  fixedHeader: {
    marginTop: 40,
  },
  logo: {
    width: "50%",
    marginBottom: 30,
  },
  sender: {
    fontSize: 10,
  },
  row: {
    flexDirection: "row",
    padding: 2,
    border: true,
    marginTop: -1,
  },
  cell1: {
    paddingRight: 10,
    fontSize: 9,
  },
  cell2: {
    paddingLeft: 65,
    marginLeft: "auto",
  },
  billingAndDelivery: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  customerInfo: {
    border: true,
    borderRadius: 3,
    padding: 15,
    width: 225,
    fontSize: 10,
  },
  table: {
    marginTop: 25,
    width: "100%",
  },
  col1: {
    width: "5%",
  },
  col2: {
    width: "35%",
  },

  col3: {
    width: "10%",
  },
  col4: {
    width: "15%",
  },
  col5: {
    width: "35%",
  },
  tableHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderTop: true,
    borderBottom: true,
    backgroundColor: "#f2f2f2",
    borderRight: true,
    borderRightColor: "black",
  },
  headerCell: {
    borderRight: true,
    borderLeft: true,
    borderColor: "black",
    borderRightColor: "#f2f2f2",
    padding: 6,
    fontWeight: "bold",
    textTransform: "capitalize",
    fontSize: 8,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: true,
    borderTop: true,
    marginTop: -1,
    borderRight: true,
    borderRightColor: "black",
  },
  tableCell: {
    borderLeft: true,
    borderColor: "black",
    padding: 6,
    fontSize: 10,
    textOverflow: "hidden",
  },
  signatures: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  signatureTable: {
    width: "48%",
    border: true,
    borderBottom: "none",
  },
  signatureRow: {
    borderBottom: true,
    flexDirection: "row",
  },
  signatureCell1: {
    borderRight: true,
    width: "30%",
    padding: 2,
    fontSize: 8,
  },
  terms: {
    padding: 3,
    border: true,
    marginTop: 20,
  },
  pageNumber: {
    position: "absolute",
    right: "30px",
    top: "20px",
    fontSize: 9,
    color: "grey",
  },
  serialPage: {
    backgroundColor: "white",
    paddingHorizontal: 30,
    paddingVertical: 30,
    fontSize: 12,
  },
  serialTitle: {
    fontWeight: 800,
    fontSize: 16,
    marginTop: 15,
    marginBottom: 5,
  },
  serialTable: {
    width: "100%",
  },
  serialRow: {
    flexDirection: "row",
    borderBottom: true,
    padding: 5,
  },
  serialCell1: {
    width: "30%",
  },
  serialCell2: {
    width: "70%",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
})

export default DeliveryNotePdf
