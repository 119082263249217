import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

const TopSellingItemsChart = ({ data }) => {
  // Group the items by SKU and calculate the total revenue for each item
  const itemTotals = {}
  data.forEach(item => {
    const sku = item.item
    if (!itemTotals[sku]) {
      itemTotals[sku] = {
        quantitySold: 0,
        totalRevenue: 0
      }
    }
    itemTotals[sku].quantitySold += item.quantity
    itemTotals[sku].totalRevenue += item.sub_total
  })

  // Sort the items by total revenue in descending order
  const items = Object.keys(itemTotals)
    .sort((a, b) => itemTotals[b].totalRevenue - itemTotals[a].totalRevenue)
    .slice(0, 5)

  // Calculate the total revenue
  const totalRevenue = Object.values(itemTotals).reduce(
    (acc, item) => acc + item.totalRevenue,
    0
  )

  // Calculate the percentage of total revenue for each item
  const percentages = items.map(
    item => (itemTotals[item].totalRevenue / totalRevenue) * 100
  )

  const chartData = {
    labels: items,
    datasets: [
      {
        label: 'Top Selling Items',
        data: percentages,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#e84393', '#2ecc71']
      }
    ]
  }

  return (
    <div className='w-100 mt-5 mb-3'>
      <div className='d-flex justify-content-between mb-3'>
        <div>
          <h3 className='font-weight-bold'>Top Selling Items as a % Revenue</h3>
        </div>
      </div>
      <Pie data={chartData} />
    </div>
  )
}

export default TopSellingItemsChart
