import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { navigate } from 'gatsby'

Chart.register(...registerables)

const ItemBarChart = ({ data }) => {
  // Group the items by SKU and calculate the total revenue for each item
  const itemTotals = {}
  data.forEach(item => {
    const sku = item.item
    if (!itemTotals[sku]) {
      itemTotals[sku] = {
        quantitySold: 0,
        totalRevenue: 0
      }
    }
    itemTotals[sku].quantitySold += item.quantity
    itemTotals[sku].totalRevenue += item.sub_total
  })

  // Extract the SKUs, total revenue, and quantity sold from the item totals object
  const skus = Object.keys(itemTotals)
  const totalRevenue = Object.values(itemTotals).map(item => item.totalRevenue)
  const quantitySold = Object.values(itemTotals).map(item => item.quantitySold)

  const chartData = {
    labels: skus,
    datasets: [
      {
        label: 'Total Quantity Sold',
        data: quantitySold,
        backgroundColor: '#36A2EB'
      },
      {
        label: 'Total Revenue',
        data: totalRevenue,
        backgroundColor: '#FF6384'
      }
    ]
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 200,
            callback: function (value) {
              return 'Rs ' + value.toLocaleString() // format y-axis label as currency
            }
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            callback: function (value) {
              return value // use SKU as x-axis label
            }
          }
        },
        {
          onClick: function (evt, activeElements) {
            if (activeElements[0]) {
              // Find item ID
              const id = data.find(
                item => item.item === activeElements[0].element._model.label
              )?.id
              // Navigate to item edit page
              navigate(`/app/edit-product/${id}`)
            }
          }
        }
      ]
    }
  }

  return (
    <div className='w-100 mt-5 mb-3'>
      <div className='d-flex justify-content-between mb-3'>
        <div>
          <h3 className='font-weight-bold'>Top Selling Items</h3>
        </div>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  )
}

export default ItemBarChart
