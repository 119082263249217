import { toast } from "react-toastify"
import axios from "axios"

// Get invoices from db
export const setInvoices = data => {
  return async (dispatch, getState) => {
    const skip = data?.skip || 1
    const limit = data?.limit || 25
    const search = data?.search || ""

    dispatch({
      type: `SET_INVOICE_LOADING`,
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      let searchParam = search

      // Check if search term is not numeric
      if (search !== "" && !/^\d+$/.test(search)) {
        // Assume it's a customer name and try to find the customer codes
        const customers = getState().customer.customers // Access customers from Redux state
        // Find matching customers based on a case-insensitive substring match
        const matchingCustomers = customers.filter(customer =>
          customer.name
            .trim()
            .toLowerCase()
            .includes(search.trim().toLowerCase())
        )

        // Take the top result as the search parameter
        const topCustomer = matchingCustomers[0]

        // Use the customer code of the top result as the search term
        if (topCustomer) {
          searchParam = topCustomer.code
        }
      }

      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/invoices?page=${skip}&limit=${limit}${
          searchParam && "&search=" + encodeURIComponent(searchParam)
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "SET_INVOICES",
        data: res.data.invoices,
      })
      dispatch({
        type: "SET_TOTAL_INVOICES",
        data: res.data.totalInvoices,
      })
      dispatch({
        type: "SET_TOTAL_PAGES",
        data: res.data.totalPages,
      })
      dispatch({
        type: `SET_INVOICE_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(
        `There was an error fetching invoices. Please try again later.`
      )
      dispatch({
        type: `SET_INVOICE_LOADING`,
        data: false,
      })
    }
  }
}

// Get all invoices from db without pagination (for payment receipt invoice modal)
export const getAllInvoices = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: "SET_INVOICE_LOADING",
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/all-invoices`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: "SET_INVOICES",
        data: res.data.invoices,
      })
    } catch (error) {
      console.error("Error fetching invoices:", error)
      dispatch({
        type: "SET_INVOICE_ERROR",
        data: error,
      })
    } finally {
      dispatch({
        type: "SET_INVOICE_LOADING",
        data: false,
      })
    }
  }
}

export const reasonForCancle = (id, reason, user) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/reasonForCancle/${id}`,
        { reasonForCancel: reason, cancelRequestedBy: user.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: Invoice cancelled Request Success!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}

export const reverceReasonForCancle = id => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/reverseReasonForCancel/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: reversed cancelled Request Success!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}

export const cancelInvoice = (id, products) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token
    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/invoices/${id}`,
        { cancelled: true, products },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      toast.success("Success: Invoice cancelled!")
      return true
    } catch (e) {
      toast.error(`Error: ${e.response.data.message}`)
    }
  }
}
