import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Col, Button, Table } from "react-bootstrap"
import {
  updatePrice,
  updateCost,
  deleteProduct,
} from "../state/actions/product"
import { SyncLoader } from "react-spinners"
import { formatNumber } from "../state/utils"
import BackButton from "./backButton"
import AddStock from "./inventory/addStock"
import RemoveStock from "./inventory/removeStock"
import SerialNumberModal from "./invoices/serialNumberModal"
import { confirmAlert } from "react-confirm-alert"

// Styles
import styles from "../styles/components/editProduct.module.scss"

const EditProduct = ({
  product,
  updatePrice,
  loading,
  updateCost,
  deleteProduct,
  admin,
  canEditPrice,
  canAddStock,
  canRemoveStock,
  canDeleteProduct,
}) => {
  const [edit, toggleEdit] = useState(false)
  const [editPriceValue, setEditPriceValue] = useState(product?.price)

  const [editCost, setEditCost] = useState(false)
  const [editCostValue, setEditCostValue] = useState(product?.cost)

  const handleupdatePrice = e => {
    updatePrice({ productSKU: product?.sku, price: editPriceValue })
    toggleEdit(false)
  }

  const handleUpdateCost = e => {
    updateCost({ productSKU: product?.sku, cost: editCostValue })
    setEditCost(false)
  }

  const confirmProductDeletion = e => {
    confirmAlert({
      title: "Confirm product deletion",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteItem(),
        },
        {
          label: "No",
        },
      ],
    })
  }

  const handleDeleteItem = () => {
    deleteProduct({ productSKU: product?.sku })
  }

  return (
    <>
      {product ? (
        <div className={styles.wrapper}>
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="title">
              Product <span>{product.sku}</span>
            </h1>

            {canDeleteProduct && (
              <Button
                size="lg"
                variant="danger"
                onClick={() => confirmProductDeletion()}
              >
                Delete Product
              </Button>
            )}
          </div>
          <BackButton to="/app/products" />
          {product && (
            <Row className={styles.content}>
              <Col lg={3}>
                {product.productImages &&
                  product.productImages.map((image, i) => (
                    <div key={i} className={styles.image}>
                      <img
                        src={image.fields.file.url}
                        alt={image.fields.description}
                        style={{
                          display: "block",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          width: "auto",
                          height: "auto",
                        }}
                      />
                    </div>
                  ))}
              </Col>
              {loading ? (
                <Col
                  style={{
                    textAlign: "center",
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <SyncLoader loading={loading} color="#2980b9" size={20} />
                </Col>
              ) : (
                <Col>
                  <Table>
                    <tbody>
                      <tr>
                        <td className={styles.label}>SKU</td>
                        <td> {product.sku}</td>
                      </tr>
                      <tr>
                        <td className={styles.label}>Title</td>
                        <td>{product.productName}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Description</td>
                        <td>{product.productDescription}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Category</td>
                        <td>{product.category}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Manufacturer</td>
                        <td>{product.manufacturer}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Warranty</td>
                        <td>{product.warranty}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Country of Origin</td>
                        <td>{product.countryOfOrigin}</td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Stock in Hand</td>
                        <td>
                          <span>{product.stock}</span>
                          <span className="ml-5">
                            {product.serialNumbers &&
                              product.serialNumbers.length > 0 && (
                                <SerialNumberModal
                                  productSerials={product.serialNumbers}
                                  mode="list"
                                />
                              )}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td className={styles.label}>Stock in Transit</td>
                        <td>{product.stockInTransit}</td>
                      </tr>

                      {admin && (
                        <tr>
                          <td className={styles.label}>Cost</td>
                          {!editCost ? (
                            <td>{formatNumber(product.cost)}</td>
                          ) : (
                            <td>
                              <input
                                type="number"
                                name="editCost"
                                value={editCostValue}
                                onChange={e => setEditCostValue(e.target.value)}
                                id="editCost"
                                autoFocus
                              />
                            </td>
                          )}
                        </tr>
                      )}

                      <tr>
                        <td className={styles.label}>Price</td>
                        {!edit ? (
                          <td>{formatNumber(product.price)}</td>
                        ) : (
                          <td>
                            <input
                              type="number"
                              name="editPrice"
                              value={editPriceValue}
                              onChange={e => {
                                setEditPriceValue(e.target.value)
                              }}
                              id="editPrice"
                              autoFocus
                            />
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </Table>

                  <div className="action-buttons d-flex">
                    {admin && !edit && (
                      <Button
                        size="lg"
                        style={{ marginRight: "6px" }}
                        variant="light"
                        onClick={() => {
                          setEditCost(!editCost)
                          const costInput = window.document.getElementById(
                            "editCost"
                          )
                          if (costInput) {
                            costInput.focus()
                          }
                        }}
                      >
                        {editCost ? "Cancel" : "Edit Cost"}
                      </Button>
                    )}

                    {editCost && (
                      <Button
                        size="lg"
                        variant="danger"
                        onClick={handleUpdateCost}
                      >
                        Save Cost
                      </Button>
                    )}

                    {canEditPrice && !editCost && (
                      <Button
                        size="lg"
                        style={{ marginRight: "6px" }}
                        variant={"light"}
                        onClick={() => {
                          toggleEdit(!edit)
                          const input = window.document.getElementById(
                            "editPrice"
                          )
                          if (input) {
                            input.focus()
                          }
                        }}
                      >
                        {edit ? "Cancel" : "Edit Price"}
                      </Button>
                    )}
                    {edit && (
                      <Button
                        size="lg"
                        variant="danger"
                        onClick={handleupdatePrice}
                      >
                        Save Price
                      </Button>
                    )}
                    {!edit && !editCost && canAddStock && (
                      <AddStock product={product} />
                    )}
                    {canRemoveStock && !edit && !editCost && (
                      <RemoveStock product={product} />
                    )}
                  </div>
                </Col>
              )}
            </Row>
          )}
        </div>
      ) : (
        <Col style={{ textAlign: "center", display: "block", margin: "auto" }}>
          <SyncLoader loading={loading} color="#2980b9" size={20} />
        </Col>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => {
  return {
    product: state.product.products.find(product => product.id === props.id),
    loading: state.product.editProductLoading,
    admin: state.auth.auth.user.admin,
    canEditPrice: state.auth.auth.user.canEditPrice,
    canRemoveStock: state.auth.auth.user.canRemoveStock,
    canAddStock: state.auth.auth.user.canAddStock,
    canDeleteProduct: state.auth.auth.user.canDeleteProduct,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePrice: data => dispatch(updatePrice(data)),
    updateCost: data => dispatch(updateCost(data)),
    deleteProduct: data => dispatch(deleteProduct(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)
