import { toast } from "react-toastify"
import axios from "axios"

// Get all delivery notes from db
export const getDeliveryNotes = data => {
  return async (dispatch, getState) => {
    const skip = data?.skip || 1
    const limit = data?.limit || 25
    const search = data?.search || ""

    dispatch({
      type: `SET_DELIVERY_NOTES_LOADING`,
      data: true,
    })

    const token = getState().auth.auth.token

    try {
      let searchParam = search

      // Check if search term is not numeric
      if (search !== "" && !/^\d+$/.test(search)) {
        // Assume it's a customer name and try to find the customer codes
        const customers = getState().customer.customers // Access customers from Redux state

        // Find matching customers based on a case-insensitive substring match
        const matchingCustomers = customers.filter(customer =>
          customer.name.toLowerCase().includes(search.toLowerCase())
        )

        // Take the top result as the search parameter
        const topCustomer = matchingCustomers[0]

        // Use the customer code of the top result as the search term
        if (topCustomer) {
          searchParam = topCustomer.code
        }
      }

      const res = await axios.get(
        `${
          process.env.GATSBY_API_CALL
        }/delivery-notes?page=${skip}&limit=${limit}${
          searchParam && "&search=" + encodeURIComponent(searchParam)
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: res.data.deliveryNotes,
      })

      dispatch({
        type: "SET_TOTAL_DELIVERY_NOTES",
        data: res.data.totalDeliveryNotes,
      })

      dispatch({
        type: "SET_TOTAL_PAGES",
        data: res.data.totalPages,
      })

      dispatch({
        type: `SET_DELIVERY_NOTES_LOADING`,
        data: false,
      })
    } catch (e) {
      console.log(e)
      toast.error(
        `There was an error fetching delivery notes. Please try again later.`
      )
      dispatch({
        type: `SET_DELIVERY_NOTES_LOADING`,
        data: false,
      })
    }
  }
}

// Update dispatch date
export const updateDispatchDate = (id, dispatchDate) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          dispatchDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.dispatchDate = dispatchDate
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes,
      })
      toast.success("Success: Dispatch date updated!")
    } catch (e) {
      toast.error("Error: " + e.response.data.error)
    }
  }
}

// Update delivery method
export const updateDeliveryMethod = (id, deliveryMethod) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          deliveryMethod,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.deliveryMethod = deliveryMethod
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes,
      })

      toast.success("Success: Delivery method updated!")
    } catch (e) {
      toast.error("Error: " + e.response.data.error)
    }
  }
}

// Update Delivery Note status
export const updateDeliveryNoteStatus = (id, status) => {
  return async (dispatch, getState) => {
    const token = getState().auth.auth.token

    try {
      await axios.patch(
        `${process.env.GATSBY_API_CALL}/delivery-note/${id}`,
        {
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const newDeliveryNotes = getState().deliveryNotes.deliveryNotes.map(
        dn => {
          if (dn._id === id) {
            dn.status = status
          }

          return dn
        }
      )

      dispatch({
        type: `SET_DELIVERY_NOTES`,
        data: newDeliveryNotes,
      })

      toast.success("Success: Delivery note status updated!")
    } catch (e) {
      toast.error("Error: " + e.response.data.error)
    }
  }
}
