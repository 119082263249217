import React, { useEffect } from 'react'
import { connect } from "react-redux"
import InventoryPDF from './inventoryPDF'
import InventoryXlsx from './inventoryXlsx'
import Serial from './serial'
import { setInvoices } from '../../state/actions/invoices'
import { getDeliveryNotes } from '../../state/actions/deliveryNotes'


const Inventory = (props) => {
    useEffect(()=>{
        props.setInvoices()
        props.getDeliveryNotes()
    },[])
    
  return (
    <div>
      {props.products.length>0 && <InventoryPDF data={props.products}></InventoryPDF>}
       {props.products.length>0 && <InventoryXlsx data={props.products}></InventoryXlsx>}
        {/* <Serial customers={props.customers} invoices={props.invoices} deliveryNotes={props.deliveryNotes} data={props.products}></Serial> */}
    </div>
  )
}

const mapStateToProps = state => {
    return {     
        products: state.product.products,
        invoices: state.invoices.invoices,
        deliveryNotes: state.deliveryNotes.deliveryNotes,
        customers: state.customer.customers,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        setInvoices: () => dispatch(setInvoices()),
        getDeliveryNotes: () => dispatch(getDeliveryNotes()),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Inventory) 