import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { FaChartPie, FaDownload } from "react-icons/fa"
import axios from "axios"
import Select from "react-select"
import DatePicker from "react-datepicker"
import moment from "moment"

const CustomerCumulativeInvoiceReport = ({ customers }) => {
  const [startDate, setStartDate] = useState(moment().startOf("month").toDate())
  const [endDate, setEndDate] = useState(moment().toDate())
  const [customer, setCustomer] = useState("")
  const [data, setData] = useState([])
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const downloadReport = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${process.env.GATSBY_API_CALL}/reports/cumulative-invoice-amount/data`,
        {
          params: { startDate, endDate, customer },
          responseType: "blob",
        }
      )

      window.open(URL.createObjectURL(res.data))
    } catch (error) {
      setError(error.response?.data?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const customerOptions = [{ value: "", label: "All Customers" }].concat(
    customers.map(customer => ({
      value: customer.code,
      label: `${customer.code} - ${customer.name}`,
    }))
  )

  return (
    <>
      <h2 className="pb-3 font-weight-bold">
        Customer Cumulative Invoice Report
      </h2>
      <div className="row d-flex align-items-end">
        <div className="col-md-3 d-flex flex-column">
          <label>Start date: </label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            className="w-100 px-3 py-2"
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-3 d-flex flex-column">
          <label>End date: </label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            className="w-100 px-3 py-2"
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-3">
          <label>Customer: </label>
          <Select
            isSearchable={true}
            defaultValue={customerOptions[0]}
            options={customerOptions}
            onChange={e => setCustomer(e.value)}
          />
        </div>
        <div className="col-md-3 d-flex flex-column flex-md-row">
          <Button
            onClick={downloadReport}
            size="lg"
            variant="primary"
            className="px-4 py-3 d-flex align-items-center justify-content-center"
            disabled={isLoading || !startDate || !endDate}
          >
            <FaDownload className="mr-2" />
            Download
          </Button>
        </div>

        {/* Loading spinner */}
        {isLoading && (
          <div className="col-md-12 text-center mt-5">
            <div className="spinner-border mt-5" role="status" />
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="alert alert-danger mt-5" role="alert">
            {error}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  customers: state.customer.customers,
})

export default connect(mapStateToProps)(CustomerCumulativeInvoiceReport)
