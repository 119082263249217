import React, { useEffect } from 'react'
import {getDeliveryNotes} from '../../state/actions/deliveryNotes'
import {setInvoices} from '../../state/actions/invoices'
import { connect } from "react-redux"
import MonthlySales from './monthlySales'

const Sales = (props) => {

    useEffect(()=>{
        props.getDeliveryNotes()
        props.setInvoices()
    },[])

   

    const arr=[]
    for(let i=0;i<props.invoices.length;i++){
        
        if(props.invoices[i].cancelled==false){
          
            for(let j=0;j<props.invoices[i].invoiceProducts.length;j++){
               

                for(let k=0;k<props.products.length;k++){
                    
                    

                     if(props.invoices[i].invoiceProducts[j].sku==props.products[k].sku){
                        let discount
                        let customer
                       
                      
                        for(let m=0;m<props.customers.length;m++){
                            if(props.invoices[i].customerCode==props.customers[m].code){
                                customer=props.customers[m].name
                            }
                        }
                        
                        arr.push({sku:props.invoices[i].invoiceProducts[j].sku,price:props.invoices[i].invoiceProducts[j].price,invNo:props.invoices[i].invoiceNumber,cost:props.products[k].cost,fullprice:props.invoices[i].invoiceProducts[j].price * props.invoices[i].invoiceProducts[j].quantity,quantity:props.invoices[i].invoiceProducts[j].quantity,InvDate:props.invoices[i].date,discountType:props.invoices[i].invoiceProducts[j]?.discountType,discount:props.invoices[i].invoiceProducts[j].discount,customer:customer})

                     }
                    // sku:products[i].sku,price:products[i].price,invNo:products[i].invoices[j].invoiceNumber ,cost:products[i].cost ,fullprice:products[i].invoices[j].invoiceProducts[k].price ,quantity:products[i].invoices[j].invoiceProducts[k].quantity,InvDate:products[i].invoices[j].date, dispatchDate:products[i].invoices[j].dispatchDate,discountType:products[i].invoices[j].invoiceProducts[k].discountType ,discount:products[i].invoices[j].invoiceProducts[k].discount
                 }
            }
        }
    }
   

  return (
    <div>
        <MonthlySales arr={arr}></MonthlySales>
    </div>
  )


}


const mapStateToProps = state => {
    return {
        quotations: state.quotation.quotations,
        customers: state.customer.customers,
        products: state.product.products,
        invoices: state.invoices.invoices,
        deliveryNotes: state.deliveryNotes.deliveryNotes,

    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getDeliveryNotes: () => dispatch(getDeliveryNotes()),
        setInvoices: () => dispatch(setInvoices()),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Sales)